import React, { useState, useEffect } from 'react'
import MainContentLayout from '../../Layout/MainContentLayout'
import { InputText } from 'primereact/inputtext'
import { Row, Col, Button, Tooltip, Spinner } from 'reactstrap'
import Modals from '../../shared/Modals'
import StatusBadge from '../../shared/StatusBadge'
import StatusDropdown from '../../shared/StatusDropdown'
import server from '../../../server'
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

export default function ManufacturersPharmacy() {
  const [manufacturerData, setManufacturerData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activeManufacturer, setActiveManufacturer] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [filterStatus, setFilterStatus] = useState('')


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleFilterChange = (status) => {
    setFilterStatus(status)
  }

  const filteredManufacturers = manufacturerData
    .filter((manufacturer) =>
      manufacturer.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((manufacturer) =>
      filterStatus ? manufacturer.status === filterStatus : true
    )

  const getManufacturerData = () => {
    setLoading(true)
    server
      .get('/manufacturers/getManafucaturerWithStatus', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturerData = response?.data?.manufacturers || []
        setManufacturerData(manufacturerData)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching Manufacturer Data:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getManufacturerData()
  }, [])

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Account number is required'),
    reps: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required'),
      })
    ),
  })

  const handleActivate = (values) => {
    const formData = {
      manufacturerId: activeManufacturer?.id,
      manufacturerCode: values.accountNumber,
      reps: values.reps,
    }
    server
      .post('manufacturerPharmacyCode/pharmacy', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('Activation Response:', response.data)
        setIsModalVisible(false)
        getManufacturerData()
      })
      .catch((error) => {
        console.error('Error activating manufacturer:', error)
      })
    console.log('Form Data Submitted:', formData)
  }

  return (
    <MainContentLayout title='Manufacturers'>
      <div className='d-flex justify-content-end align-items-center mb-4 gap-4'>
        <StatusDropdown onChange={handleFilterChange} />
        <div className='p-inputgroup w-25' style={{ height: '40px' }}>
          <InputText
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            className='bg-primary'
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            <span className='mdi mdi-magnify text-white'></span>
          </Button>
        </div>
      </div>

      {loading ? (
        <div className='d-flex justify-content-center mt-5'>
          <Spinner color='primary' />
        </div>
      ) : (
        <div>
          <Row className='g-3'>
            {filteredManufacturers.length > 0 ? (
              filteredManufacturers.map((manufacturer, index) => (
                <Col key={index} lg='4' md='6' sm='12' className='d-flex'>
                  <div className='card-background-color mb-4 h-100 w-100 d-flex'>
                    <div className='py-2 px-3 d-flex flex-column justify-content-center gap-3 w-100'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <StatusBadge status={manufacturer.status} />

                        <i
                          className='mdi mdi-information-outline'
                          id={`infoTooltip-${index}`}
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                          }}
                        ></i>
                        <Tooltip
                          style={{ backgroundColor: '#AFB0B0' }}
                          placement='top'
                          isOpen={tooltipOpen[`infoTooltip-${index}`]}
                          target={`infoTooltip-${index}`}
                          toggle={() => toggleTooltip(`infoTooltip-${index}`)}
                        >
                          For further assistance, call the generic manufacturer
                          or contact your rep
                        </Tooltip>
                      </div>
                      <div className='d-flex p-0 justify-content-between'>
                        <span className='fw-bold fs-5'>
                          {manufacturer?.name}
                        </span>
                        {manufacturer?.new === true && (
                          <span
                            style={{ color: '#2FB500' }}
                            className='self-stretch my-auto text-sm text-center text-lime-600'
                          >
                            New
                          </span>
                        )}
                      </div>

                      {manufacturer.status === 'available' ? (
                        <div className='d-flex justify-content-between gap-2'>
                          <button
                            className='btn btn-primary w-100'
                            onClick={() => {
                              setActiveManufacturer(manufacturer)
                              setIsModalVisible(true)
                            }}
                          >
                            Connect Now
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {manufacturer?.AccountNo ? (
                            <>
                              <span className=' fs-6'>Account no: </span>
                              <span>{manufacturer?.AccountNo}</span>
                            </>
                          ) : (
                            <span className='fs-6'>
                              We will send a notification once available
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <Col>
                <div className='text-center p-4'>
                  <span className='fs-5 fw-light text-muted'>
                    No Manufacturers match this filter or search.
                  </span>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}

      {/* Modal for Activating Manufacturer */}
      <Modals
        ModalHeader='Activate Manufacturer'
        btnName='Activate'
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        onButtonClick={() => {
          document.getElementById('formik-submit-btn').click()
        }}
      >
        <div className='modal-body'>
          <Formik
            initialValues={{
              accountNumber: '',
              reps: [{ name: '', email: '' }],
            }}
            validationSchema={validationSchema}
            onSubmit={handleActivate}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className='form-group mb-1'>
                  <label htmlFor='accountNumber'>Account no.</label>
                  <Field
                    type='text'
                    className='form-control'
                    id='accountNumber'
                    name='accountNumber'
                    placeholder='Enter Account no.'
                  />
                  <ErrorMessage
                    name='accountNumber'
                    component='div'
                    className='text-danger'
                  />
                </div>

                {/* <span className='fw-light lead fs-6 text-decoration-underline mb-3'>
                  Don't have an account? Download the credit application here.
                </span> */}
                <span className='fw-light lead fs-6 text-decoration-underline mb-3'>
                  {activeManufacturer?.creditApplication ? (
                    <a className='text-black' href={activeManufacturer.creditApplication} download>
                      Don't have an account? Download the credit application
                      here.
                    </a>
                  ) : (
                    'No credit application available.'
                  )}
                </span>

                <FieldArray name='reps'>
                  {({ push, remove }) => (
                    <>
                      <div className='form-group col-auto d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-dark border-0 mt-2 d-flex gap-2 align-items-center'
                          id='addFieldButton'
                          onClick={() => push({ name: '', email: '' })}
                        >
                          <span className='fw-light lead fs-6'>Add Rep</span>
                          <span className='mdi mdi-plus'></span>
                        </button>
                      </div>
                      {values.reps.map((rep, index) => (
                        <div
                          className='form-row d-flex align-items-end gap-2 mb-3'
                          key={index}
                        >
                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.name`}>
                              Name of Rep {index + 1}
                            </label>
                            <Field
                              type='text'
                              className='form-control'
                              id={`reps.${index}.name`}
                              name={`reps.${index}.name`}
                              placeholder='Enter Name'
                            />
                            <ErrorMessage
                              name={`reps.${index}.name`}
                              component='div'
                              className='text-danger'
                            />
                          </div>
                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.email`}>
                              Email of Rep {index + 1}
                            </label>
                            <Field
                              type='email'
                              className='form-control'
                              id={`reps.${index}.email`}
                              name={`reps.${index}.email`}
                              placeholder='Enter Email'
                            />
                            <ErrorMessage
                              name={`reps.${index}.email`}
                              component='div'
                              className='text-danger'
                            />
                          </div>
                          {values.reps.length > 1 && (
                            <div className='form-group'>
                              <button
                                style={{ fontSize: '24px' }}
                                type='button'
                                className='text-danger bg-transparent border-0'
                                onClick={() => remove(index)}
                              >
                                <span className='mdi mdi-trash-can'></span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className='form-group mt-3 d-flex justify-content-end'>
                  <Button
                    id='formik-submit-btn'
                    type='submit'
                    color='primary'
                    style={{ display: 'none' }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modals>
    </MainContentLayout>
  )
}
