import React, { useState, useEffect, useRef } from 'react'
import Fab from '@mui/material/Fab'
import megaLoud from '../../assets/images/megaloud.svg'
import server from '../../server'

const AdvertiseButton = () => {
  const [advertisements, setAdvertisements] = useState([])
  const [loading, setLoading] = useState(false)
  const [isWindowOpen, setIsWindowOpen] = useState(false)
  const [openAds, setOpenAds] = useState([])
  const windowRef = useRef(null)

  const getAdvertisements = () => {
    setLoading(true)
    server
      .get('/ads',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const ads = response?.data?.ads || []

        // Check local storage for deleted ads and filter them out
        const deletedAds = JSON.parse(localStorage.getItem('deletedAds')) || []
        const filteredAds = ads.filter((ad) => !deletedAds.includes(ad.id))

        setAdvertisements(filteredAds)
        setOpenAds(Array(filteredAds.length).fill(true))
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching advertisements:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAdvertisements()
  }, [])

  const toggleWindow = () => {
    setIsWindowOpen(!isWindowOpen)
  }

  const handleClickOutside = (event) => {
    if (
      windowRef.current &&
      !windowRef.current.contains(event.target) &&
      !event.target.closest('.advertising-button')
    ) {
      setIsWindowOpen(false)
    }
  }

  useEffect(() => {
    if (isWindowOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isWindowOpen])

  const saveDeletedAdToLocalStorage = (ad) => {
    const deletedAds = JSON.parse(localStorage.getItem('deletedAds')) || []
    deletedAds.push(ad)
    localStorage.setItem('deletedAds', JSON.stringify(deletedAds))
  }
  const closeAd = (index) => {
    const adToClose = advertisements[index]
    saveDeletedAdToLocalStorage(adToClose.id)

    setOpenAds((prevOpenAds) => {
      const updatedAds = [...prevOpenAds]
      updatedAds[index] = false
      return updatedAds
    })
  }

  return (
    <div>
      <Fab
        className='bg-primary advertising-button'
        aria-label='add'
        onClick={toggleWindow}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
        }}
      >
        <img src={megaLoud} alt='advertise' />
      </Fab>

      {isWindowOpen && (
        <div className='advertising-button-window' ref={windowRef}>
          {advertisements?.map(
            (ad, index) =>
              openAds[index] && (
                <div
                  key={index}
                  style={{
                    border: '1px solid #ddd',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    marginBottom: '10px',
                  }}
                >
                  <div className='d-flex justify-content-between align-items-start mb-2'>
                    {/* <h5 className='m-0'>{ad.title}</h5> */}
                    <button
                      type='button'
                      className='btn-close border-0 bg-transparent '
                      aria-label='Close'
                      onClick={() => closeAd(index)}
                      style={{
                        border: 'none',
                        background: 'transparent',
                        fontSize: '16px',
                        marginLeft: 'auto',
                      }}
                    >
                      <span className='mdi mdi-close'></span>
                    </button>
                  </div>

                  <div
                    style={{
                      margin: '15px 0',
                      height: '160px',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '5px',
                      overflow: 'hidden',
                    }}
                  >
                    {ad.image && (
                      <img
                        src={ad.image}
                        alt={ad.title}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </div>

                  <p className='fw-bold mb-1'>{ad.title}</p>
                  <p className='text-muted mb-3'>{ad.message}</p>
                  {ad?.link ? (
                    <a
                      href={`https://${ad.link}`} 
                      className='text-decoration-none text-primary'
                    >
                      Learn More →
                    </a>
                  ) : null}
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}

export default AdvertiseButton
