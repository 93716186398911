import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

const Modals = ({
  ModalHeader,
  btnName,
  children,
  visible,
  setVisible,
  disabled,
  onButtonClick,
  loading,
  wait = false,
}) => {
  const footerContent = (
    <div className='d-flex justify-content-center'>
      <Button
        loading={loading}
        disabled={disabled ? disabled : false}
        label={!loading ? btnName : ''}
        onClick={() => {
          onButtonClick()
          if (!wait) {
            !disabled && setVisible(false)
          }
        }}
        autoFocus
      />
    </div>
  )

  return (
    <div className='card '>
      <Dialog
        header={ModalHeader}
        visible={visible}
        style={{ width: '40rem' }}
        breakpoints={{ '960px': '75rem', '641px': '90vw' }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        {children}
      </Dialog>
    </div>
  )
}

export default Modals
