import React from 'react'
import logopng from '../../assets/images/Logo.png'
import { Link } from 'react-router-dom'

import { Row, Col, CardBody, Card, Container } from 'reactstrap'

const AuthFormsLayout = (props) => {
  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2' />
        </Link>
      </div>
      <div className='account-pages mt-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={4}>
              <Card className='overflow-hidden'>
                <div className='bg-primary'>
                  <div className='text-primary text-center p-4 d-flex flex-column align-items-start'>
                    <img
                      src={logopng}
                      height='13'
                      className='mb-2'
                      alt='logo'
                    />

                    <h5
                      className='text-white mb-0'
                      style={{ fontSize: '2rem' }}
                    >
                      {props.title}
                    </h5>

                    <p
                      style={{
                        color: 'rgba(255, 255, 255, 0.8',
                        fontSize: 'smaller',
                      }}
                    >
                      {props.subtitle}
                    </p>

                    {/* <Link to='/' className='logo logo-admin'>
                      <img src={logoSm} height='30' alt='logo' />
                    </Link> */}
                  </div>
                </div>

                <CardBody className='px-4 pb-4 pt-0'>
                  <div>{props.children}</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AuthFormsLayout
