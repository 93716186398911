import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'

import MainContentLayout from '../Layout/MainContentLayout'
import { formatCurrency, formatDate, formatPercentage } from '../shared/utils'
import server from '../../server'
import {
  DateFilterTemplate,
  DropDownTemplate,
  InputFieldTemplate,
  MinMaxTemplate,
} from '../shared/FilterTemplates'

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'
//core
import 'primereact/resources/primereact.min.css'
//icons
import 'primeicons/primeicons.css'

export default function ManufacturerOrders() {
  const dt = useRef()

  const [searchParams, setSearchParams] = useSearchParams()
  const [manufacturersOptions, setManufacturersOptions] = useState([])

  const currentDate = new Date()

  const [orders, setOrders] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0)
  const [csvLoading, setcsvLoading] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [lazyState, setLazyState] = useState({
    rows: 10,
    page: 0,
    first: 0,
    sortField: '',
    sortOrder: '',
    filters: {
      pharmacyName: { value: '' },
      manufacturer: { value: '' },
      pharmacyEmail: { value: '' },
      supplierOrderNo: { value: '' },
      recordType: { value: '' },
      orderDate: {
        value:
          Number(searchParams.get('today')) === 1
            ? [
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate() - 1
                ),
                currentDate,
              ]
            : '',
      },
      shipDate: { value: '' },
      status: { value: '' },
      totalInvoiceAmount: { value: '' },
    },
  })

  const navigate = useNavigate()

  const initLazyState = () => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        pharmacyName: { value: '' },
        manufacturer: { value: '' },

        pharmacyEmail: { value: '' },
        supplierOrderNo: { value: '' },
        recordType: { value: '' },
        orderDate: {
          value:
            Number(searchParams.get('today')) === 1
              ? [
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() - 1
                  ),
                  currentDate,
                ]
              : '',
        },
        shipDate: { value: '' },
        status: { value: '' },
        totalInvoiceAmount: { value: '' },
      },
    }))
  }

  const onRowSelect = (event) => {
    const order = orders.find(
      (item) => item.supplierInvoiceNo === event.data.supplierInvoiceNo
    )
    navigate(`${event.data.supplierOrderNo}`, { state: order })
  }

  const getData = () => {
    const currentDate = new Date()
    const currentDateShip = new Date()
    let startDate
    let endDate
    let startDateShip
    let endDateShip
    const pharmacyName = lazyState?.filters?.pharmacyName.value
    const pharmacyEmail = lazyState?.filters?.pharmacyEmail.value
    const dates = lazyState?.filters?.orderDate.value
    const shipDates = lazyState?.filters?.shipDate.value
    const manufacturer = lazyState?.filters?.manufacturer.value
    const totalInvoiceAmount = lazyState?.filters?.totalInvoiceAmount.value
    const status = lazyState?.filters?.status.value
    const supplierOrderNo = lazyState?.filters?.supplierOrderNo.value

    if (dates) {
      startDate = dates[0]
      endDate = dates[1] ? dates[1] : currentDate
    }
    if (shipDates) {
      startDateShip = shipDates[0]
      endDateShip = shipDates[1] ? shipDates[1] : currentDateShip
    }

    const requestParams =
      `?page=${lazyState?.page ? lazyState.page + 1 : 1}` +
      `&limit=${lazyState?.rows}` +
      `${pharmacyName ? '&pharmacyName=' + pharmacyName : ''}` +
      `${pharmacyEmail ? '&pharmacyEmail=' + pharmacyEmail : ''}` +
      `${status ? '&status=' + status : ''}` +
      `${manufacturer ? '&manufacturerId=' + manufacturer : ''}` +
      `${
        totalInvoiceAmount?.min
          ? '&greaterThanTotalAmount=' + totalInvoiceAmount.min
          : ''
      }` +
      `${
        totalInvoiceAmount?.max
          ? '&lessThanTotalAmount=' + totalInvoiceAmount.max
          : ''
      }` +
      `${dates ? '&startDate=' + formatDate(startDate) : ''}` +
      `${dates ? '&endDate=' + formatDate(endDate) : ''}` +
      `${shipDates ? '&shipStartDate=' + formatDate(startDateShip) : ''}` +
      `${shipDates ? '&shipEndDate=' + formatDate(endDateShip) : ''}` +
      `${supplierOrderNo ? '&orderNo=' + supplierOrderNo : ''}` +
      `${lazyState?.sortField ? '&sortByField=' + lazyState.sortField : ''}` +
      `${lazyState?.sortOrder ? '&sortByOrder=' + lazyState.sortOrder : ''}`

    setTableLoading(true)

    server
      .get(`/invoicesHeaders/manufacturer/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const ordersData = response.data.invoicesHeaders
        setOrders(ordersData)
        setTotalRecords(response.data.totalCount)
        setTableLoading(false)
        // setError(null)
      })
      .catch((error) => {
        setTableLoading(false)
      })
    // ================================= Manufacturer Request for the filter dropdown ============================================
    server
      .get(`/users/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturers = response.data.user.manufacturers
        const options = manufacturers.map((manufacturer) => ({
          name: manufacturer.name,
          value: manufacturer.id,
        }))
        setManufacturersOptions(options)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }
  const exportCSV = () => {
    const currentDate = new Date()
    const currentDateShip = new Date()
    let startDate
    let endDate
    let startDateShip
    let endDateShip
    const pharmacyName = lazyState?.filters?.pharmacyName.value
    const pharmacyEmail = lazyState?.filters?.pharmacyEmail.value
    const manufacturer = lazyState?.filters?.manufacturer.value
    const dates = lazyState?.filters?.orderDate.value
    const shipDates = lazyState?.filters?.shipDate.value
    const totalInvoiceAmount = lazyState?.filters?.totalInvoiceAmount.value
    const status = lazyState?.filters?.status.value
    const supplierOrderNo = lazyState?.filters?.supplierOrderNo.value

    if (dates) {
      startDate = dates[0]
      endDate = dates[1] ? dates[1] : currentDate
    }
    if (shipDates) {
      startDateShip = shipDates[0]
      endDateShip = shipDates[1] ? shipDates[1] : currentDateShip
    }

    const requestParams =
      `?page=${lazyState?.page ? lazyState.page + 1 : 1}` +
      `&limit=${lazyState?.rows}` +
      `${pharmacyName ? '&pharmacyName=' + pharmacyName : ''}` +
      `${pharmacyEmail ? '&pharmacyEmail=' + pharmacyEmail : ''}` +
      `${status ? '&status=' + status : ''}` +
      `${manufacturer ? '&manufacturerId=' + manufacturer : ''}` +
      `${
        totalInvoiceAmount?.min
          ? '&greaterThanTotalAmount=' + totalInvoiceAmount.min
          : ''
      }` +
      `${
        totalInvoiceAmount?.max
          ? '&lessThanTotalAmount=' + totalInvoiceAmount.max
          : ''
      }` +
      `${dates ? '&startDate=' + formatDate(startDate) : ''}` +
      `${dates ? '&endDate=' + formatDate(endDate) : ''}` +
      `${shipDates ? '&shipStartDate=' + formatDate(startDateShip) : ''}` +
      `${shipDates ? '&shipEndDate=' + formatDate(endDateShip) : ''}` +
      `${supplierOrderNo ? '&orderNo=' + supplierOrderNo : ''}` +
      `${lazyState?.sortField ? '&sortByField=' + lazyState.sortField : ''}` +
      `${lazyState?.sortOrder ? '&sortByOrder=' + lazyState.sortOrder : ''}`
    setcsvLoading(true)
    server
      .get(`/invoicesHeaders/exportOrders${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'orders.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setcsvLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
        console.log('error', error)
      })
  }

  useEffect(() => {
    checkClearBtn()
    return getData()
  }, [lazyState])

  useEffect(() => initLazyState(), [searchParams])

  const onPage = (event) => {
    setLazyState(event)
  }

  const onSort = (event) => {
    setLazyState(event)
  }

  const onFilter = (event) => {
    setLazyState(event)
  }

  // BODY TEMPLATES
  const statusBodyTemplate = (order) => {
    let statusColor
    let textColor
    switch (order.status) {
      case 'Cancelled':
        statusColor = '#EF4444'
        textColor = '#EF4444'
        break
      case 'Pending':
        statusColor = '#86888b'
        textColor = '#86888b'

        break
      case 'Confirmed':
        statusColor = '#22c55e'
        textColor = '#22c55e'
        break
    }

    return (
      <span
        className={`d-flex justify-content-center py-1 rounded-pill`}
        style={{ border: `1px solid ${statusColor}`, color: `${textColor}` }}
      >
        {order.status}
      </span>
    )
  }
  const orderDateTemplate = (order) => formatDate(order.orderDate)
  const manufacturerTemplate = (rowData) =>
    rowData.invoicesDetails[0].manufacturer.name
  const shipDateTemplate = (order) => formatDate(order.shipDate)
  const totalInvoiceAmountTemplate = (order) =>
    formatCurrency(order.totalInvoiceAmount)

  // Filter TEMPLATES
  const statusFilterElement = (options) => (
    <DropDownTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e.value, options.index)}
      placeholder='Select status'
      options={[
        { name: 'Confirmed', value: 'Confirmed' },
        { name: 'Pending', value: 'Pending' },
        { name: 'Cancelled', value: 'Cancelled' },
      ]}
    />
  )

  const ManufacturerFilterElement = (options) => {
    return (
      <DropDownTemplate
        state={options.value}
        stateHandler={(e) => {
          options.filterCallback(e.value, options.index)
        }}
        placeholder='Select Manufacturer Name'
        options={manufacturersOptions}
      />
    )
  }

  const orderTotalFilterElement = (options) => (
    <MinMaxTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
    />
  )

  const orderDateFilterElement = (options) => (
    <DateFilterTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e.value, options.index)}
    />
  )

  const orderNumberFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Order no.'
    />
  )

  const pharmacyNameFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Pharmacy Name'
    />
  )
  const pharmacyEmailFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Pharmacy Email'
    />
  )

  const checkClearBtn = () => {
    if (lazyState?.sortField || lazyState?.sortOrder) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in lazyState?.filters) {
      if (lazyState?.filters[key].value) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }

  // TOOLBARS
  const leftToolbarTemplate = () => (
    <Button
      type='button'
      icon='pi pi-filter-slash'
      label='Clear'
      outlined
      onClick={() => {
        initLazyState()
        setSearchParams('')
      }}
      disabled={clearBtnDisabled}
    />
  )

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header='Pharmacy Name'
          rowSpan={2}
          sortable
          field='pharmacyName'
          filter
          filterElement={pharmacyNameFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Pharmacy Email'
          rowSpan={2}
          sortable
          field='pharmacyEmail'
          filter
          filterElement={pharmacyEmailFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Manufacturer Name'
          rowSpan={2}
          sortable
          field='manufacturer'
          filter
          filterElement={ManufacturerFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Number'
          rowSpan={2}
          sortable
          field='supplierOrderNo'
          filter
          filterElement={orderNumberFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Date'
          rowSpan={2}
          sortable
          field='orderDate'
          filter
          filterElement={orderDateFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        {/* <Column
          header='Ship Date'
          rowSpan={2}
          sortable
          field='shipDate'
          filter
          filterElement={orderDateFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
       
        /> */}

        <Column
          header='Status'
          rowSpan={2}
          sortable
          field='status'
          filter
          filterElement={statusFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Total'
          rowSpan={2}
          sortable
          field='totalInvoiceAmount'
          filter
          filterElement={orderTotalFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
      </Row>
    </ColumnGroup>
  )

  return (
    <MainContentLayout
      title='Your Orders'
      dt={exportCSV}
      handleExport={true}
      loading={csvLoading}
    >
      <Toolbar className='mb-2' left={leftToolbarTemplate} />
      <DataTable
        ref={dt}
        lazy
        headerColumnGroup={headerGroup}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={totalRecords}
        paginator
        value={orders}
        selectionMode='single'
        selection={selectedOrder}
        onSelectionChange={(e) => setSelectedOrder(e.value)}
        dataKey='id'
        onRowSelect={onRowSelect}
        tableStyle={{ minWidth: '50rem' }}
        loading={tableLoading}
        filters={lazyState?.filters}
        sortField={lazyState?.sortField}
        sortOrder={lazyState?.sortOrder}
        rows={lazyState?.rows}
        first={lazyState?.first}
        onPage={onPage}
        onSort={onSort}
        onFilter={onFilter}
        removableSort
        showGridlines
      >
        <Column field='pharmacy.name' />
        <Column field='user.email' />
        <Column
          field='invoicesDetails[0].manufacturer.name'
          body={manufacturerTemplate}
        />
        <Column field='supplierOrderNo' />
        <Column field='orderDate' body={orderDateTemplate} />
        {/* <Column field='shipDate' body={shipDateTemplate} /> */}
        <Column field='status' body={statusBodyTemplate} />
        <Column field='totalInvoiceAmount' body={totalInvoiceAmountTemplate} />
      </DataTable>
    </MainContentLayout>
  )
}
