import React, { useContext, useEffect } from 'react'
import { MegaMenu } from 'primereact/megamenu'
import originsManganegment from '../../../assets/images/ORIGINS-MANAGEMENT.png'
import originsDirect from '../../../assets/images/ORIGINS-DIRECT.png'
import { AuthenticationContext } from '../../../Auth/authentication.context'
import { useNavigate } from 'react-router-dom'
const Systems = () => {
  const navigation = useNavigate()
  const { setModule, user } = useContext(AuthenticationContext)

  useEffect(() => {
    const moduleFromLocalStorage = localStorage.getItem('module')
    if (moduleFromLocalStorage) {
      setModule(moduleFromLocalStorage)
    } else if (user) {
      const originsDirectModule = user.pharmacyModules.find(
        (module) => module.name === 'Origins Direct'
      )
      if (originsDirectModule) {
        setModule(originsDirectModule.name)
      } else {
        setModule(user?.pharmacyModules[0]?.name)
      }
    }
  }, [user])

  const itemRenderer = (item) => {
    const originsDirectModule = user?.pharmacyModules?.find(
      (module) => item.module === module.name
    )
    return (
      <>
        {originsDirectModule !== undefined && (
          <div
            className='flex flex-column align-items-start py-3'
            style={{
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = 'rgb(240, 238, 238)')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = 'initial')
            }
            onClick={() => {
              localStorage.setItem('module', item.module)
              setModule(item.module)
              navigation('/')
            }}
          >
            <img
              alt='megamenu-demo'
              src={item.image}
              style={{ height: '25px', marginLeft: '20px' }}
            />
          </div>
        )}
      </>
    )
  }

  const items = [
    {
      label: 'Systems',
      icon: 'pi pi-system',
      items: [
        [
          {
            label: '',
            items: [
              {
                key: '23',
                image: originsDirect,
                module: 'Origins Direct',
                template: itemRenderer,
              },
              {
                key: '2321',
                image: originsManganegment,
                module: 'Origins Management',
                template: itemRenderer,
              },
            ],
          },
        ],
      ],
    },
  ]

  return <MegaMenu model={items} />
}

export default Systems
