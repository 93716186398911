import React, { useState, useEffect } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { formatCurrency } from '../shared/utils'
import AddToOrder from './Modals/AddToOrder'
import EditOrderItem from './Modals/EditOrderItem'
const SearchResults = ({
  results,
  pageLimit,
  totalCount,
  handleMyOrder,
  tableLoading,
  editItem,
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [rowItemData, setRowItemData] = useState({})
  const [state, setState] = useState({
    first: 0,
    rows: 10,
    page: 0,
  })
  const rowClassName = (rowData) => {
    return rowData.orderable === false ? 'gray-background' : ''
  }

  const packSizeBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.packSize}/{rowData.sellingPriceUnit}
      </div>
    )
  }
  const minMultBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.minimumOrderQuantity}/{rowData.multipleOrderQuantity}
      </div>
    )
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <div className='d-flex justify-content-center'>
        {!rowData.quantity ? (
          <Button
            disabled={!rowData.orderable} // Disable button if not orderable
            type='button'
            label='Add to order'
            className='text-center'
            onClick={() => {
              setModalVisible(true)

              setRowItemData(rowData)
            }}
          />
        ) : (
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => {
              setEditModalVisible(true)
              setRowItemData(rowData)
            }}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    pageLimit(state)
  }, [state])

  return (
    <>
      <DataTable
        //   ref={dt}
        value={results}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={totalCount}
        rowClassName={rowClassName}
        paginator
        lazy
        showGridlines
        loading={tableLoading}
        dataKey='id'
        removableSort
        // globalFilterFields={['name']}
        emptyMessage={() => {
          return (
            <div
              style={{
                height: '365px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontWeight: '400' }}>
                Use the search bar to find products
              </span>
            </div>
          )
        }}
        sortField={state.sortField}
        sortOrder={state.sortOrder}
        filters={state.filters}
        rows={state.rows}
        first={state.first}
        onPage={(e) => setState(e)}
        onSort={(e) => {
          e.first = 0
          setState(e)
        }}
        onFilter={(e) => setState(e)}
      >
        {/* <Column
          field='itemCode'
          header='Item    '
            sortable
            filter
            showFilterMenuOptions={false}
            filterElement={manufacturerFilterElement}
          style={{ fontWeight: 'bold' }}
        /> */}
        <Column
          field='retailUpcCode'
          header='UPC#'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={orderAmountFilterElement}
          //   body={orderAmountBodyTemplate}
        />
        <Column
          field='itemDescription'
          header='Product Description'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          field='itemGroupMajorDescription'
          header='Manufacturer'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          field='drugIdentificationNo'
          header='DIN/NPN'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          header='Pack Size'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          body={packSizeBodyTemplate}
        />
        <Column
          header='Min/Mult'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          body={minMultBodyTemplate}
        />
        <Column
          header='Price'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
          body={(e) => formatCurrency(e.sellingPrice)}
        />

        <Column body={actionBodyTemplate} style={{ minWidth: '200px' }} />
      </DataTable>
      <AddToOrder
        visible={isModalVisible}
        setVisible={setModalVisible}
        data={rowItemData}
        handleMyOrder={handleMyOrder}
      />
      <EditOrderItem
        visible={isEditModalVisible}
        setVisible={setEditModalVisible}
        data={rowItemData}
        editItem={editItem}
      />
    </>
  )
}

export default SearchResults
