import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import server from '../../server'

import { ToastPopUp } from '../shared/utils'
import { useNavigate, useParams } from 'react-router'
import DeleteModal from '../shared/DeleteModal'

const DeleteAds = forwardRef((props, ref) => {
  console.log(props)

  const { ads, setAds, emptyAds } = props
  const [deleteAdsDialog, setDeleteAdsDialog] = useState(false)

  const toast = useRef(null)
  const { DeleteRef } = ref

  const { din: paramDin } = useParams()
  const navigate = useNavigate()

  // Opens Delete Dialog Modal
  useImperativeHandle(DeleteRef, () => ({
    confirmDeleteAds(ads) {
      setAds(ads)
      setDeleteAdsDialog(true)
    },
  }))

  // Closes Delete Dialog Modal
  const hideDeleteAdsDialog = () => {
    setDeleteAdsDialog(false)
  }

  // Handels Delete Molecule
  const deleteAds = () => {
    let id = ads.id

    server
      .delete(`/ads/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        props.getData()
        ToastPopUp(
          toast,
          'success',
          'Ad Deleted',
          'Ads has been Deleted successfully.'
        )
      })
      .catch((e) => {
        ToastPopUp(toast, 'error', 'Error', e.response.data.message)
      })

    setDeleteAdsDialog(false)
   
  }

  // Confirm Delete yes or no template
  const deleteAdsDialogFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        outlined
        onClick={hideDeleteAdsDialog}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        onClick={deleteAds}
      />
    </React.Fragment>
  )
  const adTitle = ads?.title || 'No Title';

  return (
    <>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>

      <DeleteModal
        state={deleteAdsDialog}
        footer={deleteAdsDialogFooter}
        onHide={hideDeleteAdsDialog}
        deletedItem={ads}
        name={adTitle}
      />
    </>
  )
})

export default DeleteAds
