import React, { useState, useEffect ,useContext } from 'react'
import OriginsManagement from './OriginsManagement'
import OriginsDirect from './OriginsDirect'
import { AuthenticationContext } from '../../../Auth/authentication.context'

const Index = () => {
  const { module } = useContext(AuthenticationContext)
  const [userModule, setUSerModule] = useState('')

  useEffect(() => {
    if (module) {
      setUSerModule(module)
    }
  }, [module])
  if (userModule === 'Origins Direct') {
    return <OriginsDirect userModule={userModule} />
  } else if (userModule === 'Origins Management') {
    return <OriginsManagement userModule={userModule} />
  } else {
    return <div>User has no System or unrecognized System.</div>
  }
}

export default Index
