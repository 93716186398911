import React, { useContext } from 'react'
import { AuthenticationContext } from '../Auth/authentication.context'
import { Navigate, Outlet } from 'react-router-dom'

const ManufacturersPrivate = () => {
  const { user } = useContext(AuthenticationContext)

  return <>{user?.role === 'manufacturer' ? <Outlet /> : <Navigate to='/' />}</>
}

export default ManufacturersPrivate
