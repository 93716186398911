import React, { useState, useEffect } from 'react'
import { Toast } from 'primereact/toast'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import SearchResults from './SearchResults'
import MyOrders from './MyOrders'
import server from '../../server'

const AddOrders = () => {
  const [searchVal, setSearchVal] = useState('')
  const [tableLoading, setTableLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [myOrder, setMyOrder] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [state, setState] = useState({
    first: 0,
    rows: 10,
    page: 0,
  })

  useEffect(() => {
    const savedMyOrder = localStorage.getItem('myOrder')
    if (savedMyOrder) {
      setMyOrder(JSON.parse(savedMyOrder))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('myOrder', JSON.stringify(myOrder))
  }, [myOrder])

  useEffect(() => {
    handleFetchData()
  }, [state])

  const editItem = (editedItem) => {
    const MyOrderIndex = myOrder.findIndex((item) => item.id === editedItem.id)
    if (MyOrderIndex !== -1) {
      const updatedOrderData = [...myOrder]
      updatedOrderData[MyOrderIndex] = editedItem
      setMyOrder(updatedOrderData)
    }
    const MyProductIndex = products.findIndex(
      (product) => product.id === editedItem.id
    )
    const updatedProducts = [...products]
    updatedProducts[MyProductIndex] = editedItem
    setProducts(updatedProducts)
  }

  const handleMyOrder = (itemData) => {
    const index = products.findIndex((product) => product.id === itemData.id)
    const updatedProducts = [...products]
    updatedProducts[index] = itemData
    setProducts(updatedProducts)
    setMyOrder((prevMyOrder) => [...prevMyOrder, itemData])
  }

  const handleDeleteItem = (itemData) => {
    const { quantity, ...productItem } = itemData
    const index = products.findIndex((product) => product.id === itemData.id)
    if (index !== -1) {
      const updatedProducts = [...products]
      updatedProducts[index] = productItem
      setProducts(updatedProducts)
    }
    setMyOrder((prevMyOrder) =>
      prevMyOrder.filter((item) => item.id !== itemData.id)
    )
  }

  const clear = (ordableProducts) => {
    setSearchVal('')
    setProducts([])

    const updatedMyOrder = myOrder?.filter(
      (orderProduct) =>
        !ordableProducts.some(
          (product) => product.productId === orderProduct.id
        )
    )

    setMyOrder(updatedMyOrder)
    setTotalCount([])
    setState({ first: 0, rows: 10, page: 0 })
  }

  const handleFetchData = () => {
    if (searchVal !== '') {
      setTableLoading(true)
      server
        .get(`/products/`, {
          params: { q: searchVal, page: state.page + 1, limit: state.rows },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then((response) => {
          const updatedResponse = [...response.data.data]
          myOrder.forEach((orderItem) => {
            const index = updatedResponse.findIndex(
              (product) => product.id === orderItem.id
            )
            if (index !== -1) {
              updatedResponse[index] = orderItem
            }
          })
          setProducts(updatedResponse)
          setTotalCount(response.data.totalCount)
          setTableLoading(false)
        })
        .catch((error) => {
          setTableLoading(false)
        })
    }
  }

  const handleSearch = (e) => {
    handleFetchData()
  }
  return (
    <React.Fragment>
      <div className='card flex justify-content-center'>
        <Toast />
      </div>
      <div className='page-content '>
        <div className='container-fluid'>
          <h3 className='fs-3 fw-bold  mb-4'>Generics Order Pad</h3>
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <CardTitle tag='h5'>Search</CardTitle>
                  <form
                    onSubmit={(e) => {
                      handleSearch()
                      e.preventDefault()
                    }}
                  >
                    <div className='flex justify-content-end  w-100'>
                      <div className='d-flex gap-2'>
                        <span className='p-input-icon-left w-100 my-auto'>
                          <i className='pi pi-search  ' />
                          <InputText
                            placeholder='search by UPC, DIN, Name or Manufacturer'
                            className='w-100 '
                            value={searchVal}
                            onChange={(e) => setSearchVal(e.target.value)}
                          />
                        </span>
                        <Button
                          type='submit'
                          label='Search'
                          style={{ minWidth: '100px' }}
                        />
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h3 className='fs-3 fw-bold  mb-4'>Search Results</h3>
          <Row className='mb-3'>
            <Col lg='12'>
              <SearchResults
                results={products}
                pageLimit={(e) => setState(e)}
                totalCount={totalCount}
                handleMyOrder={handleMyOrder}
                tableLoading={tableLoading}
                editItem={editItem}
              />
            </Col>
          </Row>

          <h3 className='fs-3 fw-bold  mb-4'>My Order</h3>
          <Row>
            <Col lg='12'>
              <Card>
                <MyOrders
                  myOrder={myOrder}
                  handleDeleteItem={handleDeleteItem}
                  clear={clear}
                  editItem={editItem}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddOrders
