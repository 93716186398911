import React from 'react'
import Modals from '../../shared/Modals'

const SuccessModal = ({ visible, setVisible, clear }) => {
  const handleOrder = () => {
    // clear()
    setVisible = false
  }

  return (
    <Modals
      btnName={'Create New Order'}
      ModalHeader={'Order Submitted'}
      visible={visible}
      setVisible={setVisible}
      onButtonClick={handleOrder}
    >
      <div className='d-flex flex-column align-items-center justify-content-center gap-4'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='check-container'>
            <div className='check-background-page'>
              <svg
                viewBox='0 0 65 51'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7 25L27.3077 44L58.5 7'
                  stroke='white'
                  strokeWidth='13'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>

        <p>Your order has been successfully submitted!</p>
      </div>
    </Modals>
  )
}

export default SuccessModal
