import React, { useContext, useRef, useEffect, useState } from 'react'
import { AuthenticationContext } from '../../Auth/authentication.context'

import { Label, Form, FormFeedback, Input } from 'reactstrap'

import { Link, Navigate } from 'react-router-dom'
import { SelectButton } from 'primereact/selectbutton'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Toast } from 'primereact/toast'

import AuthFormsLayout from '../Layout/AuthFormsLayout'
import { Row, Col, CardBody, Card, Container } from 'reactstrap'

import { ToastPopUp } from '../shared/utils'

import AuthLayout from '../Layout/AuthLayout'

const Login = (props) => {
  const { onLogin, user, isAdmin, error, toastStatus } = useContext(
    AuthenticationContext
  )

  const toast = useRef(null)

  useEffect(() => {
    if (toastStatus.toastStatus !== undefined) {
      ToastPopUp(
        toast,
        toastStatus?.toastStatus,
        toastStatus?.summary,
        toastStatus?.msg
      )
    }
  }, [toastStatus])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    initialValues: {
      accountNo: '',
      password: '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      accountNo: Yup.string().required('Please Enter Your account Number'),
      password: Yup.string().min(8).required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      console.log('values', values)

      onLogin('', values.password, 'pharmacy', values.accountNo)
    },
  })

  document.title = 'Login'

  const options = ['Pharmacy', 'Manufacturer']
  const [value, setValue] = useState(options[0])

  return (
    <>
      <AuthLayout>
        <div className='card flex'>
          <Toast ref={toast} />
        </div>
        {user ? (
          <Navigate to='/' />
        ) : (
          <>
            {/* <AuthFormsLayout
            title='Welcome Back !'
            subtitle='Sign in to continue to Origins Direct'
          > */}
            <Card className='p-4'>
              <h2>Let's log you in Origins Direct</h2>
              <h3 className='text-center'>Login as a Pharmacy</h3>
              <Form
                className='mt-4'
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
                action='#'
                noValidate
              >
                {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                {/* <div className='d-flex justify-content-center align-items-center'>
                  <SelectButton
                    value={value}
                    onChange={(e) => setValue(e.value)}
                    options={options}
                  />
                </div> */}
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='accountNo'>
                    Account number
                  </Label>
                  <Input
                    name='accountNo'
                    className='form-control'
                    placeholder='Enter account number'
                    type='number'
                    id='accountNo'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.accountNo || ''}
                    invalid={
                      validation.touched.accountNo &&
                      validation.errors.accountNo
                    }
                  />
                  {validation.touched.accountNo &&
                  validation.errors.accountNo ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.accountNo}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className='mb-3'>
                  <Label className='form-label' htmlFor='userpassword'>
                    Password
                  </Label>
                  <Input
                    name='password'
                    value={validation.values.password || ''}
                    type='password'
                    className='form-control'
                    placeholder='Enter Password'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className='mb-3 row'>
                  <div className='col-sm-6'>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='customControlInline'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='customControlInline'
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-6 text-end'>
                    <button
                      className='btn btn-primary w-md waves-effect waves-light'
                      type='submit'
                    >
                      Log In
                    </button>
                  </div>
                </div>

                <div className='mt-2 mb-0 row'>
                  <div className='col-12 mt-4'>
                    <Link to='/forget-password'>
                      <i className='mdi mdi-lock'></i> Forget your password?
                    </Link>
                  </div>
                </div>
              </Form>
              {/* </AuthFormsLayout> */}
              {/* <div className='mt-4 text-center'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '20px 0',
                  }}
                >
                  <div style={{ flex: 1, borderTop: '1px solid #ccc' }}></div>
                  <span
                    style={{
                      padding: '0 10px',
                      fontSize: '1rem',
                      color: '#666',
                    }}
                  >
                    New to Origins Direct?
                  </span>
                  <div style={{ flex: 1, borderTop: '1px solid #ccc' }}></div>
                </div>
                <Link to='/register'>
                  <button
                    style={{
                      backgroundColor: '#f7f7f7',
                      border: '1px solid #ccc',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      cursor: 'pointer',
                    }}
                  >
                    Create a new account
                  </button>
                </Link>
              </div> */}
              <div className='mt-4 text-center'>
                <div className='line-divider'>
                  <div className='line'></div>
                  <span>Don't have an account ?</span>
                  <div className='line'></div>
                </div>
                <Link to='/register'>
                  <button className='create-account-btn'>Register Now</button>
                </Link>
              </div>

          
              <div className='mt-4 d-flex gap-2 justify-content-center'>
                <Link
                  to='/manufacturer-login'
                  className='fw-medium text-primary'
                >
                  Login as a Manufacturer?
                </Link>

                <Link to='/admin-login' className='fw-medium text-primary'>
                  Login as an admin?
                </Link>
              </div>
            </Card>
          </>
        )}
      </AuthLayout>
    </>
  )
}

export default Login
