import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatCurrency } from '../shared/utils'
import { formatDate } from '../shared/utils'
import { useContext } from 'react'
import { AuthenticationContext } from '../../Auth/authentication.context'
export default function ManufacturerOrderDetailsHeader(props) {
  const [order] = useState([props.order])
  const [total, setTotal] = useState(null)

  useEffect(() => {
    if (props?.total) {
      setTotal(props?.total)
    }
  }, [props.total])

  const totalInvoiceBodyTemplate = (order) =>
    formatCurrency(
      total !== null && props?.status === 'Confirmed'
        ? formatCurrency(total)
        : props?.status === 'Cancelled'
        ? formatCurrency(0.0)
        : formatCurrency(order?.totalInvoiceAmount)
    )
  const orderDateBodyTemplate = (order) => formatDate(order.orderDate)

  const statusBodyTemplate = (order) => {
    let statusColor
    let textColor
    let status
    if (props.status) {
      status = props.status
    } else {
      status = order.status
    }
    switch (status) {
      case 'Cancelled':
        statusColor = '#EF4444'
        textColor = '#EF4444'
        break
      case 'Pending':
        statusColor = '#86888b'
        textColor = '#86888b'

        break
      case 'Confirmed':
        statusColor = '#22c55e'
        textColor = '#22c55e'
        break
    }

    return (
      <span
        className={`d-flex justify-content-center py-1 rounded-pill`}
        style={{ border: `1px solid ${statusColor}`, color: `${textColor}` }}
      >
        {status}
      </span>
    )
  }
  return (
    <div className='card mb-5'>
      <DataTable showGridlines value={order} tableStyle={{ minWidth: '50rem' }}>
        <Column header='Pharmacy Name' rowSpan={2} field='pharmacy.name' />
        <Column
          header='Account Number'
          rowSpan={2}
          hidden={
            props.order?.supplier?.name === 'Origins Direct' ? true : false
          }
          field='customerAccountCode'
        />
        <Column header='Order Number' rowSpan={2} field='supplierOrderNo' />
        <Column
          header='Order Date'
          rowSpan={2}
          field='orderDate'
          body={orderDateBodyTemplate}
        />
        <Column header='Status' field='status' body={statusBodyTemplate} />
        <Column
          header='Order Total'
          rowSpan={2}
          field='totalInvoiceAmount'
          body={totalInvoiceBodyTemplate}
        />
      </DataTable>
    </div>
  )
}
