import React, { useState, useEffect, useContext } from 'react'
import OriginsManagementOrders from './OriginsManagementOrders'
import OriginsDirectOrders from './OriginsDirectOrders'
import { AuthenticationContext } from '../../../Auth/authentication.context'

export default function PharmacyOrders() {
  const { module } = useContext(AuthenticationContext)
  const [userModule, setUSerModule] = useState('')

  useEffect(() => {
    if (module) {
      setUSerModule(module)
    }
  }, [module])

  if (userModule === 'Origins Direct') {
    return <OriginsDirectOrders  />
  } else if (module === 'Origins Management') {
    return <OriginsManagementOrders />
  } else {
    return <div>User has no System or unrecognized System.</div>
  }
}
