import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { ToastPopUp } from '../shared/utils'
import server from '../../server'
import MainContentLayout from '../Layout/MainContentLayout'
import { formatCurrency } from '../shared/utils'
import ManufacturerOrderDetailsHeader from './ManufacturerOrderDetailsHeader'
import BackButton from '../shared/backButton'
import { InputFieldTemplate } from '../shared/FilterTemplates'
const ManufacturerOrderDetails = () => {
  const { orderId } = useParams()
  const order = useLocation()
  const toast = useRef(null)

  const dt = useRef(null)
  const [orderDetails, setOrderDetails] = useState(null)
  const [state, setState] = useState({
    first: 0,
    rows: 10,
    page: 0,
  })

  const [orderData, setOrderData] = useState({})
  const [tableLoading, setTableLoading] = useState(false)
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [status, setStatus] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [details, setDetails] = useState([])
  const [totalExtendedPrice, setTotalExtendedPrice] = useState(null)
  const [newPrice, setNewPrice] = useState(null)
  const [newTotal, setNewTotal] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  const getData = () => {
    setTableLoading(true)

    server
      .get(`/invoicesDetails/?orderNumber=${orderId}&limit=1000`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const orderDetailsData = response.data.details
        if (orderDetailsData[0].invoiceHeader?.status === 'Pending') {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
        const totalExtendedPrice = orderDetailsData.reduce((total, item) => {
          return total + item.extendedPrice
        }, 0)
        const transformedDetails = orderDetailsData.map((item) => ({
          id: item?.id,
          approvedQuantity: item?.requestedQuantity,
          total: item?.extendedPrice,
        }))
        setNewTotal(totalExtendedPrice)
        setDetails([...details, ...transformedDetails])
        setTotalExtendedPrice(totalExtendedPrice)
        setStatus(orderDetailsData[0].invoiceHeader?.status)
        setOrderDetails(orderDetailsData)
        setTableLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
      })
  }

  const handleRequest = (status) => {
    let body
    if (status === 'Cancel') {
      body = { status: 'Cancelled' }
      setCancelLoading(true)
    } else if (status === 'Confirmed') {
      // Filter out the 'total' key from each object in the details array
      setConfirmLoading(true)
      const filteredDetails = details.map((detail) => {
        const { total, ...rest } = detail
        return rest
      })
      body = { status: 'Confirmed', details: filteredDetails }
    }

    server
      .patch(`/invoicesHeaders/${orderDetails[0]?.invoiceHeader?.id}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        ToastPopUp(toast, 'success', 'Order updated successfully')
        setCancelLoading(false)
        setConfirmLoading(false)
        getData()
      })
      .catch((e) => {
        ToastPopUp(toast, 'error', 'Error', e.response.data.message)
        setCancelLoading(false)
        setConfirmLoading(false)
      })
  }

  useEffect(() => {
    initState()
    getData()
  }, [])

  useEffect(() => {
    setOrderData(order?.state)
  }, [order])

  useEffect(() => {
    checkClearBtn()
  }, [state])

  useEffect(() => {
    if (details) {
      const newTotalPrice = details.reduce((total, item) => {
        return total + item.total
      }, 0)
      setNewPrice(newTotalPrice)
    }
  }, [details])

  const checkClearBtn = () => {
    if (state.sortField || state.sortOrder || globalFilterValue) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in state?.filters) {
      if (key === 'global') {
        continue
      } else if (
        state?.filters[key].constraints[0].value ||
        state?.filters[key]?.constraints[1]?.value
      ) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }

  const clearFilter = () => {
    initState()
    setGlobalFilterValue('')
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...state.filters }

    _filters['global'].value = value

    setState((prevState) => {
      return {
        ...prevState,
        filters: _filters,
      }
    })
    setGlobalFilterValue(value)
  }

  const initState = () => {
    setState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemCode: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'item.itemDescription': {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        shippedQuantity: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: 'gte' },
            { value: null, matchMode: 'lte' },
          ],
        },
        requestedQuantity: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: 'gte' },
            { value: null, matchMode: 'lte' },
          ],
        },
        unitPrice: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: 'gte' },
            { value: null, matchMode: 'lte' },
          ],
        },
        extendedPrice: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: 'gte' },
            { value: null, matchMode: 'lte' },
          ],
        },
      },
    }))
    setGlobalFilterValue('')
  }

  const handleInputChange = (rowData, value) => {
    const id = rowData?.id
    const existingItemIndex = details.findIndex((item) => item.id === id)
    if (existingItemIndex === -1) {
      setDetails([
        ...details,
        { id, approvedQuantity: value, total: value * rowData.unitPrice },
      ])
    } else {
      if (details[existingItemIndex].approvedQuantity !== value) {
        const updatedDetails = [...details]
        updatedDetails[existingItemIndex].approvedQuantity = value
        updatedDetails[existingItemIndex].total = value * rowData.unitPrice
        setDetails(updatedDetails)
      }
    }
  }

  const shippedQuantity = (rowData) => {
    return (
      <>
        {status === 'Pending' ? (
          <InputNumber
            mode='decimal'
            placeholder='Enter a number'
            showButtons
            min={0}
            max={rowData?.requestedQuantity}
            value={rowData?.requestedQuantity}
            onChange={(e) => handleInputChange(rowData, e.value)}
          />
        ) : (
          rowData?.shippedQuantity
        )}
      </>
    )
  }

  // TOOLBAR
  const topLeftToolbarTemplate = () => (
    <Button
      type='button'
      icon='pi pi-filter-slash'
      label='Clear'
      outlined
      onClick={clearFilter}
      disabled={clearBtnDisabled}
    />
  )

  const topRightToolbarTemplate = () => (
    <InputText
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      placeholder='Keyword Search'
    />
  )

  // BODY TEMPLATES
  const unitPriceBodyTemplate = (order) => formatCurrency(order.unitPrice)

  const extendedPriceBodyTemplate = (order) =>
    formatCurrency(order.requestedQuantity * order.unitPrice)

  // FILTER TEMPLATES
  const itemCodeFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      placehloder='Molecule'
      icon='pi pi-hashtag'
      type='number'
    />
  )
  const itemDescriptionFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      placehloder='Item Description'
      icon='pi pi-hashtag'
      type='text'
    />
  )

  const shippedQuantityFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      icon='pi pi-hashtag'
      type='number'
    />
  )
  const requestedQuantityFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      icon='pi pi-hashtag'
      type='number'
    />
  )
  const unitPriceFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      icon='mdi mdi-currency-usd'
      type='number'
    />
  )
  const extendedPriceFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      icon='mdi mdi-currency-usd'
      type='number'
    />
  )

  return (
    <>
      <Toast ref={toast} />

      <MainContentLayout
        title={`Order Details #${order.state.supplierOrderNo}`}
        dt={dt}
      >
        <BackButton />

        <ManufacturerOrderDetailsHeader
          order={order.state}
          total={newTotal}
          status={status}
        />

        <Toolbar
          className='mb-2'
          left={topLeftToolbarTemplate}
          right={topRightToolbarTemplate}
        />
        <DataTable
          ref={dt}
          value={orderDetails}
          paginator
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          rowsPerPageOptions={[5, 10, 25, 50]}
          loading={tableLoading}
          dataKey='id'
          removableSort
          globalFilterFields={[
            'itemCode',
            'unitPrice',
            'extendedPrice',
            'requestedQuantity',
            'item.itemDescription',
          ]}
          emptyMessage='No order details found.'
          filters={state.filters}
          sortField={state.sortField}
          sortOrder={state.sortOrder}
          rows={state.rows}
          first={state.first}
          onPage={(e) => setState(e)}
          onSort={(e) => setState(e)}
          onFilter={(e) => setState(e)}
        >
          <Column
            field='itemCode'
            header='Item Code'
            sortable
            filter
            showFilterMenuOptions={false}
            filterElement={itemCodeFilterElement}
          />
          <Column
            field='item.itemDescription'
            header='Item Description'
            sortable
            filter
            showFilterMenuOptions={false}
            filterElement={itemDescriptionFilterElement}
          />

          <Column
            header='Unit Price'
            field='unitPrice'
            body={unitPriceBodyTemplate}
            filterElement={unitPriceFilterElement}
            filter
            sortable
            showFilterMenuOptions={false}
          />
          <Column
            field='extendedPrice'
            header='Extended Price'
            body={extendedPriceBodyTemplate}
            filterElement={extendedPriceFilterElement}
            filter
            sortable
            showFilterMenuOptions={false}
          />
          <Column
            header='Requested Quantity'
            field='requestedQuantity'
            filter
            sortable
            filterElement={requestedQuantityFilterElement}
            showFilterMenuOptions={false}
          />

          <Column
            header='Confirmed Quantity'
            field='shippedQuantity'
            filter={disabled}
            sortable={disabled}
            hidden={status !== 'Cancelled' ? false : true}
            filterElement={shippedQuantityFilterElement}
            showFilterMenuOptions={false}
            body={shippedQuantity}
          />
        </DataTable>
        {status === 'Pending' && (
          <div className='d-flex justify-content-center m-5  flex-wrap'>
            <div>
              <div className='d-flex flex-column  mb-3'>
                <div className='d-flex justify-between  gap-5'>
                  <p className='fw-bold text-start px-0 m-0 w-100'>
                    Minimum Amount
                  </p>

                  {formatCurrency(
                    orderDetails[0]?.manufacturer?.gpMinOrderPrice
                  )}
                </div>
                <div className='d-flex justify-between  gap-5'>
                  <p className='fw-bold text-start px-0 m-0 w-100'>Total</p>
                  {newPrice !== totalExtendedPrice ? (
                    <del>{formatCurrency(totalExtendedPrice)}</del>
                  ) : (
                    <>{formatCurrency(totalExtendedPrice)}</>
                  )}
                </div>
                <div className='d-flex justify-content-end gap-5 '>
                  <p className='fw-bold px-0  m-0 w-100'></p>
                  <p className='fw-bold px-0 m-0'>
                    {newPrice !== totalExtendedPrice ? (
                      <>{formatCurrency(newPrice)}</>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-end justify-content-center gap-2  flex-wrap'>
                <Button
                  type='button'
                  label='Confirm Order'
                  className='text-center'
                  style={{ minWidth: '151px' }}
                  loading={confirmLoading}
                  disabled={
                    orderDetails[0]?.manufacturer?.gpMinOrderPrice > newPrice ||
                    newPrice === 0
                      ? true
                      : false
                  }
                  onClick={() => {
                    handleRequest('Confirmed')
                  }}
                />
                <Button
                  type='button'
                  label='Cancel Order'
                  className='text-center'
                  severity='danger'
                  style={{ minWidth: '151px' }}
                  loading={cancelLoading}
                  onClick={() => {
                    handleRequest('Cancel')
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </MainContentLayout>
    </>
  )
}

export default ManufacturerOrderDetails
