import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import server from '../../../server'

import { ToastPopUp } from '../../shared/utils'
import { useNavigate, useParams } from 'react-router'
import DeleteModal from '../../shared/DeleteModal'

const DeleteManufacture = forwardRef((props, ref) => {
  const { manufacturer, setManufacturer, emptyManufacturer} = props
  const [loading, setLoading] = useState(false) 
  const [deleteManufacturerDialog, setDeleteManufacturerDialog] =
    useState(false)

  const toast = useRef(null)
  const { DeleteRef } = ref

  const { din: paramDin } = useParams()
  const navigate = useNavigate()

  // Opens Delete Dialog Modal
  useImperativeHandle(DeleteRef, () => ({
    confirmDeleteManufacturer(manufacturer) {
      setManufacturer(manufacturer)
      setDeleteManufacturerDialog(true)
    },
  }))

  // Closes Delete Dialog Modal
  const hideDeleteManufacturerDialog = () => {
    setDeleteManufacturerDialog(false)
  }

  // Handels Delete Molecule
  const deleteManufacturer = () => {
    const manufacturerData = {
      gpIsOrdable: false,
    }
    setLoading(true)
    server.patch(`/manufacturers/${manufacturer.id}`, manufacturerData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
    .then((response) => {
      
    setDeleteManufacturerDialog(false)
      // paramDin
        // ? navigate('/admin/molecules', { state: molecule })
        // : 
        props.getData()
        setLoading(false)
      ToastPopUp(
        toast,
        'success',
        'Manufacturers Deleted',
        'Manufacturers has been Deleted successfully.'
      )
    })
    .catch((e) => {
      ToastPopUp(toast, 'error', 'Error', e.response.data.message)
      setLoading(false)
      
    setDeleteManufacturerDialog(false)
    })

    // setManufacturer(emptyManufacturer)
  }

  // Confirm Delete yes or no template
  const deleteManufacturerDialogFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        outlined
        onClick={hideDeleteManufacturerDialog}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        onClick={deleteManufacturer}
        loading={loading}
      />
    </React.Fragment>
  )

  return (
    <>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <DeleteModal
        state={deleteManufacturerDialog}
        footer={deleteManufacturerDialogFooter}
        onHide={hideDeleteManufacturerDialog}
        deletedItem={manufacturer}
        name={manufacturer.name}
      />
    </>
  )
})

export default DeleteManufacture
