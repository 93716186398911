import React, { useContext, useState } from 'react'
import SidebarElement from './SidebarElement'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { AuthenticationContext } from '../../../Auth/authentication.context'

const ManufacturerSidebarContent = () => {
  const [nodes] = useState([
    {
      key: '1',
      label: 'Dashboard',
      link: '/',
      icon: 'mdi mdi-home',
    },
    {
      key: '2',
      label: 'Profile',
      link: '/manufacturer/profile',
      icon: 'mdi mdi-account',
    },
    // {
    //   key: '3',
    //   label: 'Orders',
    //   link: 'orders',
    //   icon: 'mdi mdi-file-document-multiple',
    // },
    {
      key: '3',
      label: 'orders',
      link: '/manufacturer/manufacturerOrders',
      icon: 'mdi mdi-file-document-multiple',
    },
    {
      key: '4',
      label: 'Logout',
      link: 'logout',
      icon: 'mdi mdi-logout',
    },
  ])

  return (
    <>
      {nodes.map((menuItem) => (
        <SidebarElement
          key={menuItem.key}
          name={menuItem.label}
          link={menuItem.link}
          iconClass={menuItem.icon}
          notifications={menuItem.notifications}
          children={menuItem.children}
        />
      ))}
    </>
  )
}

export default ManufacturerSidebarContent
