import React, { useState, useEffect } from 'react'
import Modals from '../../shared/Modals'
import { Label, Input } from 'reactstrap'
import { formatCurrency } from '../../shared/utils'
import { InputNumber } from 'primereact/inputnumber'

const EditOrderItem = ({ visible, setVisible, data, editItem }) => {
  const [orderDetails, setOrderDetails] = useState({
    quantity: 0,
    subtotal: 0,
  })

  useEffect(() => {
    setOrderDetails({
      quantity: data?.quantity,
      subtotal: data?.subtotal,
    })
  }, [data])

  const updateSubtotal = (quantity) => {
    const newSubtotal = data.sellingPrice * quantity
    setOrderDetails({
      quantity: quantity,
      subtotal: newSubtotal,
    })
  }
  const handleEditToOrder = () => {
    const updatedData = {
      ...data,
      quantity: orderDetails.quantity,
      subtotal: orderDetails.subtotal,
    }
    editItem(updatedData)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleEditToOrder()
      setVisible(false)
    }
  }
  return (
    <Modals
      btnName={'Save'}
      ModalHeader={'Edit Item Details'}
      visible={visible}
      setVisible={setVisible}
      disabled={
        orderDetails.subtotal === null ||
        orderDetails.subtotal === 0 ||
        orderDetails.subtotal === ''
          ? true
          : false
      }
      onButtonClick={handleEditToOrder}
    >
      <div className='row'>
        <h5 className='col-sm'>Item</h5>
        <p className='col-sm'>{data?.itemCode}</p>
      </div>
      <div className='row'>
        <h5 className='col-sm'>UPC#</h5>
        <p className='col-sm'>{data?.retailUpcCode}</p>
      </div>
      <div className='row'>
        <h5 className='col-sm'>Product Description</h5>
        <p className='col-sm'>{data?.itemDescription}</p>
      </div>
      <div className='row'>
        <h5 className='col-sm'>Manufacturer</h5>
        <p className='col-sm'>{data?.itemGroupMajorDescription}</p>
      </div>
      <div className='row'>
        <h5 className='col-sm'>DIN/NPN</h5>
        <p className='col-sm'>{data?.drugIdentificationNo}</p>
      </div>
      <div className='row'>
        <h5 className='col-sm'>Price</h5>
        <p className='col-sm'>{formatCurrency(data?.sellingPrice)}</p>
      </div>
      <div className='row'>
        <Label className='form-label col-sm' htmlFor='quantity'>
          Quantity
        </Label>

        <InputNumber
          inputId='quantity'
          value={orderDetails.quantity}
          onChange={(e) => updateSubtotal(e.value)}
          mode='decimal'
          placeholder='Enter Quantity'
          showButtons
          min={1}
          className=' col-sm mb-2'
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className='row'>
        <h5 className='col-sm'>Subtotal</h5>
        <p className='col-sm'>
          {formatCurrency(
            orderDetails.subtotal === null || orderDetails.subtotal === 0
              ? data.subtotal
              : orderDetails.subtotal
          )}
        </p>
      </div>
    </Modals>
  )
}

export default EditOrderItem
