import React from 'react'
import { Toolbar } from 'primereact/toolbar'
import { ScrollTop } from 'primereact/scrolltop'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { colors } from '@mui/material'
import paLogo from '../../assets/images/Logo.png'   
const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <nav class='navbar navbar-expand-lg bg-body-tertiary' style={{backgroundColor:"#323547" ,height:"90px"}} >
        <div class='container'>
          <a class='navbar-brand' href='/login'>
          <img src={paLogo} alt='' height='40' />
          </a>
          <button
            class='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>
        
        </div>
      </nav>
      <div className='container my-5'>
        <h2 className='fs-1 fw-bold mb-5 '>Privacy Policy</h2>
        <p className='fs-5'>
          This Privacy Policy governs how Origins Direct collects, uses,
          maintains and discloses information collected from users (each, a
          “User”) of the Origins Direct website (“Site”).
        </p>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Personal identification information</h3>
            <p className='fs-5'>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, respond to a survey, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, and phone
              number. We will collect only personal identification information
              from Users if they voluntarily submit it to us. Users can always
              refuse to supply personal identification information, except that
              it may prevent them from engaging in certain activities related to
              Site.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Non-personal identification information</h3>
            <p className='fs-5'>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer,
              technical information about Users' means of connection to our
              Site, such as the operating system and the Internet service
              providers utilized and other similar information.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Web browser cookies</h3>
            <p className='fs-5'>
              Our Site may use “cookies” to enhance User experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. Users may
              set their web browser to refuse cookies or alert you when cookies
              are being sent. If they do so, note that some parts of the Site
              may not function properly.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>How we use collected information</h3>
            <p className='fs-5'>
              Origins Direct may collect and use Users' personal information for
              the following purposes: To run and operate our site, we may need
              your information to correctly display content on the Site. To
              personalize user experience, We may use information in the
              aggregate to understand how our Users as a group use the services
              and resources provided on our Site. To send periodic emails, We
              may use the email address to send User information and updates
              about their order. It may also be used to respond to their
              inquiries, questions, and/or other requests
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>How we protect your information</h3>
            <p className='fs-5'>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Sharing your personal information</h3>
            <p className='fs-5'>
              We do not sell, trade, or rent Users' personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the above purposes.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Changes to this privacy policy</h3>
            <p className='fs-5'>
              Origins Direct has the discretion to update this privacy policy at
              any time. When we do, we will post a notification on the main page
              of our Site. We encourage Users to frequently check this page for
              any changes to stay informed about how we are helping to protect
              the personal information we collect. You acknowledge and agree
              that you must review this privacy policy periodically and become
              aware of modifications.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Your acceptance of these terms</h3>
            <p className='fs-5'>
              By using this Site, you signify your acceptance of this policy. If
              you disagree with this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </Col>
        </Row>
        <Row style={{ textAlign: 'justify' }}>
          <Col className='col-12'>
            <h3>Contacting us</h3>
            <p className='fs-5'>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please get in touch
              with us. This document was last updated on Feb 1, 2024.
            </p>
          </Col>
        </Row>
        <ScrollTop />
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
