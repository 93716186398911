import React, { useEffect, useContext } from 'react'
import { AuthenticationContext } from '../Auth/authentication.context'
import { Outlet, Navigate } from 'react-router-dom'

const Module = () => {
  const { module } = useContext(AuthenticationContext)

  useEffect(() => {
    console.log('module', module)
  }, [module])

  return <>{module === 'Origins Direct' ? <Outlet /> : <Navigate to='/' />}</>
}

export default Module
