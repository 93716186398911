import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { classNames } from 'primereact/utils'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'

import server from '../../../server'

import { ToastPopUp } from '../../shared/utils'

const AddEditManufacturer = forwardRef((props, ref) => {
  const { AddEditRef } = ref
  const { manufacturers, setManufacturers, emptyManufacturer } = props
  const [manufactureDialog, setManufactureDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isManufactureEdit, setIsManufactureEdit] = useState(false)

  const [manufacturer, setManufacturer] = useState([])
  const [manufacturerData, setManufacturerData] = useState([])
  const [loading, setLoading] = useState(false)

  const toast = useRef(null)
  useImperativeHandle(AddEditRef, () => ({
    openNew() {
      setSubmitted(false)
      setManufactureDialog(true)
      setManufacturers(emptyManufacturer)
    },

    editManufacturer(manufacturer) {
      setSubmitted(false)
      setIsManufactureEdit(true)

      const parsedCutOffTime =
        typeof manufacturer.cutOffTime === 'string'
          ? dayjs(manufacturer.cutOffTime, 'h:mm A').toDate()
          : manufacturer.cutOffTime

      setManufacturers({
        ...manufacturer,

        cutOffTime: parsedCutOffTime,
      })
      setManufactureDialog(true)
    },
  }))

  const hideDialog = () => {
    setManufactureDialog(false)
    setIsManufactureEdit(false)
  }

  // Gets table data
  const getData = () => {
    const requestParams = `?ordable=false`

    server
      .get(`/manufacturers/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturer = response?.data?.Manufacturers
        setManufacturer(manufacturer)
      })
      .catch((error) => {
        // setTableLoading(false)
        // setError(error.response.data.message)
      })
  }

  useEffect(() => {
    if (manufactureDialog === true) {
      getData()
    }
  }, [manufactureDialog])

  useEffect(() => {
    if (manufacturer) {
      const options = manufacturer.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      setManufacturerData(options)
    }
  }, [manufacturer])
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0
    let _manufacturers = { ...manufacturers }
    if (name === 'leadTime') {
      _manufacturers[name] = Math.min(val, 10)
    } else {
      _manufacturers[name] = val
    }

    setManufacturers(_manufacturers)
  }
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _manufacturers = { ...manufacturers }
    _manufacturers[`${name}`] = val

    setManufacturers(_manufacturers)
  }

  const saveManufactures = () => {
    if (
      manufacturers.id &&
      manufacturers.gpIsOrdable &&
      manufacturers.email &&
      manufacturers.cutOffTime &&
      manufacturers.leadTime
    ) {
      setLoading(true)
      const formattedCutOffTime = manufacturers.cutOffTime
        ? new Date(manufacturers.cutOffTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })
        : null

      const dataToSend = {
        gpMinOrderPrice: manufacturers.gpMinOrderPrice,
        comingSoon: manufacturers.comingSoon,
        gpIsOrdable: true,
        email: manufacturers.email,
        leadTime: manufacturers.leadTime,
        cutOffTime: formattedCutOffTime,
      }

      server
        .patch(`/manufacturers/${manufacturers.id}`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then((response) => {
          props.getData()
          if (isManufactureEdit) {
            setLoading(false)
            setManufactureDialog(false)

            ToastPopUp(
              toast,
              'success',
              'Manufacturers Updated',
              'Manufacturers has been Updated successfully.'
            )
          } else {
            setLoading(false)
            setManufactureDialog(false)
            ToastPopUp(
              toast,
              'success',
              'Manufacturers Added',
              'Manufacturers has been added successfully.'
            )
          }
          setIsManufactureEdit(false)
          setSubmitted(true)
        })
        .catch((e) => {
          ToastPopUp(toast, 'error', 'Error', e.response.data.message)
          setIsManufactureEdit(false)
          setSubmitted(true)
          setLoading(false)
        })
    } else {
      setSubmitted(true)
    }
  }

  //  Dialog footers
  const manufactureDialgeFooter = (
    <React.Fragment>
      <Button label='Cancel' icon='pi pi-times' outlined onClick={hideDialog} />
      <Button
        label={
          isManufactureEdit ? 'Update Manufacturer' : 'Add New Manufacturer'
        }
        loading={loading}
        icon='pi pi-check'
        onClick={saveManufactures}
      />
    </React.Fragment>
  )
  const statusOptions = [
    { label: 'Coming Soon', value: "true" },
    { label: 'Available', value: "false" },
  ]

  return (
    <>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <Dialog
        visible={manufactureDialog}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={
          isManufactureEdit ? 'Update Manufacturer' : 'Add New Manufacturer'
        }
        modal
        className='p-fluid'
        footer={manufactureDialgeFooter}
        onHide={() => setManufactureDialog(false)}
      >
        <div className='field mt-4'>
          <label htmlFor='ManufacturerName' className='font-bold'>
            Manufacturer Name
          </label>
          <Dropdown
            id='ManufacturerName'
            value={manufacturers.id}
            required={!isManufactureEdit}
            onChange={(e) => onInputChange(e, 'id')}
            options={manufacturerData}
            optionLabel='label'
            placeholder={
              isManufactureEdit
                ? `${manufacturers.name}`
                : 'Select Manufacturer'
            }
            filter
            // className='w-full md:w-14rem'
            readOnly={isManufactureEdit ? true : false}
            className={classNames(
              `w-full md:w-14rem ${isManufactureEdit && 'disabledInput'}`
            )}
          />
          {submitted && !manufacturers.id && (
            <small className='p-error'>Manufacturer name is required.</small>
          )}
        </div>
        <div className='field mt-4'>
          <label htmlFor='ManufacturerName' className='font-bold'>
            Status
          </label>
          <Dropdown
            id='status'
            value={manufacturers?.comingSoon}
            required={!isManufactureEdit}
            onChange={(e) => onInputChange(e, 'comingSoon')}
            options={statusOptions}
            optionLabel='label'
            placeholder={
              isManufactureEdit
                ? manufacturers.comingSoon
                  ? 'Coming Soon'
                  : 'Available'
                : 'Select Status'
            }
            filter
            className={classNames(`w-full md:w-14rem`)}
          />
          {submitted && !manufacturers.id && (
            <small className='p-error'>Manufacturer name is required.</small>
          )}
        </div>
        <div className='field mt-4'>
          <label htmlFor='minimumOrderPrice' className='font-bold'>
            Minimum Order Price
          </label>

          <InputNumber
            inputId='minimumOrderPrice'
            value={manufacturers.gpMinOrderPrice}
            required={!isManufactureEdit}
            onChange={(e) => {
              onInputNumberChange(e, 'gpMinOrderPrice')
            }}
            mode='decimal'
            placeholder='Enter Minimum Order Price'
            showButtons
            min={0}
          />
          {submitted && !manufacturers.gpMinOrderPrice && (
            <small className='p-error'>Minimum Order Price is required.</small>
          )}
        </div>
        <div className='field mt-4'>
          <label htmlFor='email' className='font-bold'>
            Email
          </label>
          <InputText
            id='email'
            type='email'
            value={manufacturers.email}
            required={!isManufactureEdit}
            onChange={(e) => onInputChange(e, 'email')}
            placeholder={
              isManufactureEdit
                ? `${manufacturers.email}`
                : 'Enter Email Address'
            }
            className={classNames('w-full md:w-14rem')}
          />
          {submitted && !manufacturers.email && (
            <small className='p-error'>email is required.</small>
          )}
        </div>
        <div className='field mt-4'>
          <label htmlFor='leadTime' className='font-bold'>
            Lead Time In Days
          </label>
          <InputNumber
            inputId='minmax-buttons'
            value={manufacturers?.leadTime}
            required={!isManufactureEdit}
            onChange={(e) => {
              onInputNumberChange(e, 'leadTime')
            }}
            mode='decimal'
            placeholder='Lead Time'
            showButtons
            min={0}
            max={10}
          />
          {submitted && !manufacturers?.leadTime && (
            <small className='p-error'>Lead time is required.</small>
          )}
        </div>

        <div className='field mt-4'>
          <label htmlFor='calendar-timeonly' className='font-bold block mb-2'>
            Cutoff Time
          </label>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <TimePicker
                id='calendar-timeonly'
                value={
                  manufacturers.cutOffTime
                    ? dayjs(manufacturers.cutOffTime)
                    : null
                }
                onChange={(selectedTime) => {
                  const formattedTime = selectedTime.valueOf()
                  // ? dayjs(selectedTime).format('h:mm A')
                  // : // : dayjs(manufacturers.cutOffTime)
                  //   dayjs(manufacturers.cutOffTime)
                  setManufacturers({
                    ...manufacturers,
                    cutOffTime: formattedTime,
                  })
                  // onInputChange(formattedTime, 'cutOffTime')
                  // onInputChange(
                  //   { target: { value: formattedTime } },
                  //   'cutOffTime'
                  // )
                }}
              />
            </div>
          </LocalizationProvider>
          {submitted && !manufacturers.cutOffTime && (
            <small className='p-error'>Cutoff time is required.</small>
          )}
        </div>
      </Dialog>
    </>
  )
})

export default AddEditManufacturer
