import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Card, CardBody, Label, Button, Spinner } from 'reactstrap'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { Checkbox } from 'primereact/checkbox'

const PharmacyProfileDetails = ({ user, reloadData, loading }) => {
  const [editMode, setEditMode] = useState(false)
  const [editName, setEditName] = useState(user?.displayName)
  const [editPharmacyName, setEditPharmacyName] = useState(user?.pharmacyName)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [userLoading, setUserLoading] = useState(false)

  const { onProfileUpdate, success } = useContext(AuthenticationContext)
  const [systemsEditData, setSystemEditArray] = useState([])

  const [originsDirectModule, setOriginsDirectModule] = useState(null)
  const [originsManagementModule, setOriginsManagementModule] = useState(null)
  const [systemsLoading, setSystemsLoading] = useState(false)

  useEffect(() => {
    const systemsData =
      user?.pharmacyModules?.map((module) => module.name) || []
    setSystemEditArray(systemsData)

    const originsDirect = systemsData.includes('Origins Direct')
    setOriginsDirectModule(originsDirect)

    const originsManagement = systemsData.includes('Origins Management')
    setOriginsManagementModule(originsManagement)
  }, [user])

  const onSystemChange = (e, moduleName) => {
    const isChecked = e.checked

    if (moduleName === 'Origins Direct') {
      setOriginsDirectModule(isChecked)
    } else if (moduleName === 'Origins Management') {
      setOriginsManagementModule(isChecked)
    }

    let updatedSystemsEditData = [...systemsEditData]

    if (isChecked && !updatedSystemsEditData.includes(moduleName)) {
      updatedSystemsEditData.push(moduleName)
    } else if (!isChecked && updatedSystemsEditData.includes(moduleName)) {
      updatedSystemsEditData = updatedSystemsEditData.filter(
        (name) => name !== moduleName
      )
    }

    setSystemEditArray(updatedSystemsEditData)
  }

  const handleEditSubmit = () => {
    onProfileUpdate(editName, editPharmacyName, selectedAddress, user.id)
    setEditMode(false)
    setUserLoading(true)
  }
  const handleSystemEditSubmit = () => {
    onProfileUpdate(
      editName,
      editPharmacyName,
      selectedAddress,
      user.id,
      systemsEditData
    )
    setEditMode(false)
    setSystemsLoading(true)
  }

  const pharmacyModules = user?.pharmacyModules.map((module, index) => (
    <span
      className='badge bg-primary rounded-pill text-light py-2 px-3 fw-bold mx-1'
      key={index}
    >
      {module.name}
    </span>
  ))

  useEffect(() => {
    if (success === true) {
      reloadData()
      setUserLoading(false)
      setSystemsLoading(false)
    }
  }, [success])
  useEffect(() => {
    if (loading === false) {
      setUserLoading(false)
    }
  }, [loading])
  return (
    <>
      <div className='text-end mb-3 '>
        <Button
          type='submit'
          color='primary'
          onClick={() => setEditMode((prevState) => !prevState)}
        >
          {editMode ? 'Back' : 'Edit Pharmacy'}
        </Button>
      </div>

      <Row>
        <Col lg='12'>
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div className='row row-cols-2 w-100'>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.displayName}</h4>
                    <h6 className='mb-1'>Pharmacy</h6>
                  </div>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.accountNo}</h4>
                    <h6 className='mb-1'>Account Number</h6>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          {userLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='form-group col '>
                    <Label className='form-label'>Full Name</Label>
                    <p>{user?.displayName}</p>
                  </div>
                  <div className='form-group col'>
                    <Label className='form-label mt-2'>Pharmacy Name</Label>
                    <p>{user?.pharmacyName}</p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Email</Label>
                    <p>{user?.email}</p>
                  </div>

                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Address</Label>

                    <p>
                      {' '}
                      {user?.address ? user?.address : 'There is no address'}
                    </p>
                  </div>
                </div>
              )}
              {editMode && (
                <div>
                  <h4 className='card-title mb-4'>Edit Pharmacy</h4>

                  {/* <Card> */}
                  {/* <CardBody> */}
                  <form className='row row-cols-2'>
                    <div className='form-group col'>
                      <Label className='form-label mt-3'>Full Name</Label>
                      <input
                        name='fullName'
                        className='form-control'
                        value={editName}
                        type='text'
                        placeholder={user?.displayName}
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    </div>

                    {/* --------------------------- */}

                    <div className='form-group col'>
                      <Label className='form-label mt-3'>Pharmacy Name</Label>
                      <input
                        name='PharmacyName'
                        className='form-control'
                        value={editPharmacyName}
                        type='text'
                        placeholder={user?.pharmacyName}
                        onChange={(e) => {
                          setEditPharmacyName(e.target.value)
                        }}
                      />
                    </div>

                    <div className='form-group col'>
                      <Label className='form-label mt-3'>Email</Label>
                      <p>{user?.email}</p>
                    </div>
                    <div className='form-group col'>
                      <Label className='form-label mt-3'>Address</Label>
                      <PlacesAutocomplete
                        placeholder={user.address}
                        setSelectedAddress={(e) => setSelectedAddress(e)}
                      />
                    </div>

                    <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handleEditSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                  {/* </CardBody> */}
                  {/* </Card> */}
                </div>
              )}
              <div className='col col-sm-6  col-lg-4 mt-3 '></div>
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {systemsLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='form-group col '>
                    <Label className='form-label'>Systems</Label>
                    <h4 className='m-0'>{pharmacyModules}</h4>
                  </div>
                </div>
              )}

              {editMode && (
                <div>
                  <h4 className='card-title mb-4'>Edit Systems</h4>

                  {/* <Card> */}
                  {/* <CardBody> */}
                  <form className='row row-cols-3'>
                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId='originsDirect'
                          name='Origins Direct'
                          value='Origins Direct'
                          onChange={(e) => onSystemChange(e, 'Origins Direct')}
                          checked={originsDirectModule}
                        />
                        <label htmlFor='originsDirect' className='mx-2 mb-0'>
                          Origins Direct
                        </label>
                      </div>
                    </div>

                    {/* --------------------------- */}

                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId=' originsManagement'
                          name=' Origins Management'
                          value='Origins Management'
                          onChange={(e) =>
                            onSystemChange(e, 'Origins Management')
                          }
                          checked={originsManagementModule}
                        />
                        <label
                          htmlFor='originsManagement'
                          className='mx-2 mb-0'
                        >
                          Origins Management
                        </label>
                      </div>
                    </div>

                    <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handleSystemEditSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                  {/* </CardBody> */}
                  {/* </Card> */}
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default PharmacyProfileDetails
