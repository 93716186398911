import React, { useContext } from 'react'

import { Navigate, Outlet } from 'react-router-dom'
import { AuthenticationContext } from '../Auth/authentication.context'

const AdminPrivate = () => {
  const { isAdmin } = useContext(AuthenticationContext)

  return <>{isAdmin ? <Outlet /> : <Navigate to='/' />}</>
}

export default AdminPrivate
