import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { Toast } from 'primereact/toast'
import { SelectButton } from 'primereact/selectbutton'
import { Form, FormFeedback, Label, Input } from 'reactstrap'
import AuthLayout from '../Layout/AuthLayout'
import { Row, Col, CardBody, Card, Container } from 'reactstrap'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { Link, useNavigate } from 'react-router-dom'

import LoggedOutFooter from '../Layout/LoggedOutFooter'
import AuthFormsLayout from '../Layout/AuthFormsLayout'
import { ToastPopUp } from '../shared/utils'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
const Register = (props) => {
  const navigate = useNavigate()
  const toast = useRef(null)
  const { onRegister, error, toastStatus } = useContext(AuthenticationContext)
  const [selectedAddress, setSelectedAddress] = useState('')

  useEffect(() => {
    if (toastStatus.toastStatus !== undefined) {
      ToastPopUp(toast, toastStatus?.toastStatus, toastStatus?.msg)
      if (toastStatus.toastStatus === 'success') {
        navigate('/Login?reg=1')
      }
    }
  }, [toastStatus])

  const options = ['Pharmacy', 'Manufacturer']
  const [value, setValue] = useState(options[0])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      pharmacyname: '',
      address: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please Enter Valid Email')
        .required('Please Enter Your Email'),
      // name: Yup.string().required('Please Enter Your Name'),
      pharmacyname: Yup.string().required('Please Enter Pharmacy name'),
      address: Yup.string().test(
        'address',
        'Please Enter Pharmacy address',
        function (value) {
          if (!selectedAddress || selectedAddress.length === 0) {
            return this.createError({
              path: 'address',
              message: 'Please Enter Pharmacy address',
            })
          }
          return true
        }
      ),
    }),
    onSubmit: (values) => {
      onRegister(
        // values.name,
        values.email,
        values.pharmacyname,
        selectedAddress
      )
    },
  })

  document.title = 'Register | Origins Direct'
  return (
    <AuthLayout>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>

      {/* <AuthFormsLayout
        title='Free Register'
        subtitle='Get your free Origins Direct account now.'
      > */}
      <Card className='p-4'>
        <h2 className='text-center'>Create a Free Account</h2>
        <h3 className='text-center'>Signup as a Pharmacy</h3>
        <Form
          className='mt-4'
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          action='#'
        >
          {/* <div className='d-flex justify-content-center align-items-center'>
            <SelectButton
              value={value}
              onChange={(e) => setValue(e.value)}
              options={options}
            />
          </div> */}
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
          {/* <div className='mb-3'>
            <Label className='form-label' htmlFor='name'>
              Full Name
            </Label>
            <Input
              name='name'
              value={validation.values.name || ''}
              type='text'
              id='name'
              className='form-control'
              placeholder='Enter Your Name'
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type='invalid'>
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div> */}
          <div className='mb-3'>
            <Label className='form-label' htmlFor='pharmacyname'>
              Pharmacy Name
            </Label>
            <Input
              name='pharmacyname'
              className='form-control'
              placeholder='Enter Pharmacy Name'
              type='text'
              id='pharmacyname'
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.pharmacyname || ''}
              invalid={
                validation.touched.pharmacyname &&
                validation.errors.pharmacyname
                  ? true
                  : false
              }
            />
            {validation.touched.pharmacyname &&
            validation.errors.pharmacyname ? (
              <FormFeedback type='invalid'>
                {validation.errors.pharmacyname}
              </FormFeedback>
            ) : null}
          </div>
          <div className='mb-3'>
            <Label className='form-label' htmlFor='useremail'>
              Email
            </Label>
            <Input
              name='email'
              className='form-control'
              placeholder='Enter Email'
              type='email'
              id='useremail'
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ''}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type='invalid'>
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>

          <div className='mb-3'>
            <Label className='form-label' htmlFor='pharmacyname'>
              Address
            </Label>
            <PlacesAutocomplete
              validation={validation}
              setSelectedAddress={setSelectedAddress}
            />
            {validation.touched.address && validation.errors.address ? (
              <FormFeedback type='invalid'>
                {validation.errors.address}
              </FormFeedback>
            ) : null}
          </div>

          <div className='mb-3 row'>
            <div className='col-12 text-end'>
              <button
                className='btn btn-primary w-md waves-effect waves-light'
                type='submit'
              >
                Register
              </button>
            </div>
          </div>

          <div className='mt-2 mb-0 row'>
            <div className='col-12 mt-4'>
              <p className='mb-0'>
                By registering you agree to Origins Systems
                <Link to='/privacy-policy' className='text-primary'>
                  &nbsp;privacy policy
                </Link>
              </p>
            </div>
          </div>
        </Form>
        {/* </AuthFormsLayout> */}

        <div className='mt-4 text-center'>
          <div className='line-divider'>
            <div className='line'></div>
            <span>Already have an account ?</span>
            <div className='line'></div>
          </div>
          <Link to='/login'>
            <button className='create-account-btn'>Login Now</button>
          </Link>
        </div>

        {/* <div className='mt-4 text-center'>
          <p>
            Already have an account ?
            <Link to='/login' className='fw-medium text-primary'>
              &nbsp;Login
            </Link>
          </p>
        </div> */}
      </Card>

      {/* <LoggedOutFooter /> */}
    </AuthLayout>
  )
}

export default Register
