import React from 'react'

function Banner() {
  return (
    <section className='profileCompletionBanner'>
      <div className='messageContainer'>
        <span className='mdi mdi-bell-outline icon mdi-shake'></span>
        <p className='message'>Please complete your profile</p>
      </div>
      <div className='actionContainer'>
        <button className='actionText bg-transparent border-0 text-white'>
          Go Complete
        </button>
        <span className='mdi mdi-arrow-right'></span>
      </div>
    </section>
  )
}

export default Banner
