import React from 'react'
import { formatCurrency } from '../../shared/utils'
const DashboardCards = ({ title, icon, value }) => {
  return (
    <div className='mb-4'>
      <div className='float-start mini-stat-img me-4'>
        <img src={icon} alt='' />
      </div>
      <h5 className='font-size-13 text-uppercase mt-0 text-white-50'>
        {title}
      </h5>
      <h4 className='fw-medium font-size-16'>{formatCurrency(value)}</h4>
    </div>
  )
}

export default DashboardCards
