//  0 - import required functions and variables
import React, { createContext, useEffect, useState } from 'react'
import server from '../server'

//  1 - create context
export const AuthenticationContext = createContext()

// export const useAuth = () => {
//   return useContext(AuthenticationContext);
// };

//  2 - create provider
export const AuthenticationContextProvider = ({ children }) => {
  //  2.0 - grap required states, states handlers and functions from other providers

  //  2.1 - define our states
  const [isLoading, setIsLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [accessToken, setAccessToken] = useState(null)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isAdmin, setIsAdmin] = useState(null)
  const [toastStatus, setToastStatus] = useState({})
  const [collapsed, setCollapsed] = useState(false)
  const [module, setModule] = useState('')

  let catchErr = () => {
    setToastStatus({
      toastStatus: 'error',
      msg: error.response.data.message,
    })
  }

  useEffect(() => {
    if (Object.keys(toastStatus).length !== 0) {
      setTimeout(() => {
        setToastStatus({})
      }, 3000)
    }
  }, [toastStatus])

  //  2.2 - define our functions
  //    2.2.1 - manage state
  //      2.2.1.1 - handle login
  const onLogin = async (email, password, role, accountNo) => {
    setIsLoading(true)
    console.log('email', email)
  
    console.log('roleeeeeeeeeeee', role)

    let requestPayload

    // Create the request payload based on the role
    if (role === 'pharmacy') {
      requestPayload = { accountNo, password, role }
    } else {
      requestPayload = { email, password, role }
    }

    server
      .post('/users/signin', requestPayload)
      .then((response) => {
        const token = response.data.accessToken
        // get user data
        server
          .get('/users', { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            setIsAdmin(response.data.user.role === 'admin' ? true : false)
            setUser(response.data.user)
            setError(null)
            setIsLoading(false)
          })
        // save access token
        setAccessToken(response.data.accessToken)
        localStorage.setItem('accessToken', response.data.accessToken)
      })
      .catch((error) => {
        setToastStatus({
          toastStatus: 'error',
          msg: error.response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        setError(error.response.data.message)
        setIsLoading(false)
      })
  }

  //      2.2.1.2 - handle register
  const onRegister = (email, pharmacyname, selectedAddress) => {
    server
      .post('/users/signup', {
        // displayName: name,
        email: email,
        name: pharmacyname,
        address: selectedAddress,
      })
      .then((response) => {
        setError(null)
        setToastStatus({
          toastStatus: 'success',
          summary: 'Email Sent',
          msg: 'An email has been sent, please verify your account.',
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error.response.data.message)
        setToastStatus({
          toastStatus: 'error',
          msg: error.response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        setIsLoading(false)
      })
  }

  const onVerify = (id, password, repeatedPassword) => {
    setIsLoading(true)

    if (password !== repeatedPassword) {
      setError('The passwords you entered do not match')
      return
    }
    if (password === '') {
      setError('You must enter a password')
      return
    }

    server
      .post('/users/verify-email', {
        uuid: id,
        password: password,
      })
      .then((response) => {
        const token = response.data.accessToken
        // get user data
        server
          .get('/users', { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            setIsAdmin(response.data.user.role === 'admin' ? true : false)
            setUser(response.data.user)
            setError(null)
            setIsLoading(false)
          })
        // save access token
        setAccessToken(response.data.accessToken)
        localStorage.setItem('accessToken', response.data.accessToken)
      })
      .catch((error) => {
        setError(error.response.data.message)
        setIsLoading(false)
      })
  }

  //      2.2.1.3 - handle send forgot password email | Post request to send email for forget password usage
  const onSendForgetPasswordEmail = (email) => {
    setIsLoading(true)

    server
      .post('/users/send-forget-password-email', {
        email,
      })
      .then((response) => {
        setToastStatus({
          toastStatus: 'success',
          msg: response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error.response.data.message)
        setToastStatus({
          toastStatus: 'error',
          msg: error.response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        console.log(error)
        setIsLoading(false)
      })
    setIsLoading(false)
  }

  //      2.2.1.4 - handle change password with old password | Patch request to change password on user profile
  const onChangePasswordProfile = (
    oldPassword,
    newPassword,
    confirmPassword
  ) => {
    setIsLoading(true)

    if (newPassword !== confirmPassword) {
      setToastStatus({
        toastStatus: 'error',
        msg: 'Password & Repeat Password fields do not match',
      })
      // setTimeout(() => {
      //   setToastStatus({})
      // }, 3000)
      setIsLoading(false)
      return false
    }

    if (oldPassword === confirmPassword) {
      setToastStatus({
        toastStatus: 'error',
        msg: 'Please enter a new password ',
      })
      // setTimeout(() => {
      //   setToastStatus({})
      // }, 3000)

      setIsLoading(false)
      return false
    }

    server
      .patch('/users/change-password', {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        setToastStatus({
          toastStatus: 'success',
          msg: response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
      })
      .catch((error) => {
        setError(error.response.data.message)
        setToastStatus({
          toastStatus: 'error',
          msg: error.response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)
        console.log(error)
        setIsLoading(false)
      })
    setIsLoading(false)
  }

  //      2.2.1.5 - handle change password using access token | Patch request to change password using access token
  const onChangePassword = (accessToken, password, repeatedPassword) => {
    if (password !== repeatedPassword) {
      setError('The passwords you entered do not match')

      return
    }
    if (password === '') {
      setError('You must enter a password')
      return
    }

    server
      .patch('/users/change-password-link', {
        token: accessToken,
        newPassword: password,
      })
      .then((response) => {
        setToastStatus({
          toastStatus: 'success',
          msg: response.data.message,
        })
        // setTimeout(() => {
        //   setToastStatus({})
        // }, 3000)

        setIsLoading(false)
        window.location.href = '/'
      })
      .catch((error) => {
        setError(error.response.data.message)
        console.log(error)
        setIsLoading(false)
      })
  }

  //      2.2.1.6 - get user data
  const onGetUserData = () => {
    setLoading(true)
    server
      .get('/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setIsAdmin(response.data.user.role === 'admin' ? true : false)
        setUser(response.data.user)
        setError(null)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  //      2.2.1.7 - handle profile update
  // const onProfileUpdate = (
  //   displayName,
  //   legalName,
  //   tradeName,
  //   pharmacyName,
  //   address,
  //   telephone,
  //   fax,
  //   shippingAddress,
  //   pharmacyOwnerName,
  //   pharmacyOwnerTelephone,
  //   pharmacyOwnerEmail,
  //   id,
  //   pharmacySystems
  // ) => {
  //   let requestBody = {}
  //   setSuccess(false)
  //   if (isAdmin) {
  //     if (id) {
  //       if (displayName) {
  //         requestBody.displayName = displayName
  //       }
  //       if (pharmacyName) {
  //         requestBody.pharmacyName = pharmacyName
  //       }
  //       if (address) {
  //         requestBody.address = address
  //       }

  //       if (pharmacySystems) {
  //         requestBody.pharmacySystems = pharmacySystems
  //       }
  //     } else {
  //       if (displayName) {
  //         requestBody.displayName = displayName
  //       }
  //     }
  //   } else {
  //     if (legalName) {
  //       requestBody.legalName = legalName
  //     }
  //     if (tradeName) {
  //       requestBody.tradeName = tradeName
  //     }
  //     if (pharmacyName) {
  //       requestBody.pharmacyName = pharmacyName
  //     }
  //     if (address) {
  //       requestBody.address = address
  //     }
  //     if (telephone) {
  //       requestBody.telephone = telephone
  //     }
  //     if (fax) {
  //       requestBody.fax = fax
  //     }
  //     if (shippingAddress) {
  //       requestBody.shippingAddress = shippingAddress
  //     }
  //     if (pharmacyOwnerName) {
  //       requestBody.pharmacyOwnerName = pharmacyOwnerName
  //     }
  //     if (pharmacyOwnerEmail) {
  //       requestBody.pharmacyOwnerEmail = pharmacyOwnerEmail
  //     }
  //     if (pharmacyOwnerTelephone) {
  //       requestBody.pharmacyOwnerTelephone = pharmacyOwnerTelephone
  //     }
  //   }

  //   if (isAdmin !== undefined) {
  //     server
  //       .patch(isAdmin && id ? `/users/${id}` : `/users`, requestBody, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  //         },
  //       })
  //       .then((response) => {
  //         setSuccess(true)
  //         setToastStatus({
  //           toastStatus: 'success',
  //           msg: response.data.message,
  //         })
  //         onGetUserData()
  //       })
  //       .catch((error) => {
  //         setError(error)
  //         setToastStatus({
  //           toastStatus: 'error',
  //           msg: 'Error updating profile',
  //         })
  //       })
  //   } else {
  //     console.error('isAdmin is not defined')
  //   }
  // }
  const onProfileUpdate = (
    displayName,
    legalName,
    tradeName,
    pharmacyName,
    address,
    telephone,
    fax,
    shippingAddress,
    pharmacyOwnerName,
    pharmacyOwnerTelephone,
    pharmacyOwnerEmail,
    id,
    pharmacySystems
  ) => {
 

    let requestBody = {}
    setSuccess(false)

    if (isAdmin) {
      console.log('Updating profile as admin', isAdmin)
      if (id) {
        console.log('Updating profile with id:', id)
        if (displayName) {
          requestBody.displayName = displayName
        }
        if (pharmacyName) {
          requestBody.pharmacyName = pharmacyName
        }
        if (address) {
          requestBody.address = address
        }
        if (pharmacySystems) {
          requestBody.pharmacySystems = pharmacySystems
        }
      } else {
        console.log(`Updating profile without id`)

        if (displayName) {
          requestBody.displayName = displayName
        }
      }
    } else {
      if (displayName) {
        requestBody.displayName = displayName
      }
      if (legalName) {
        requestBody.legalName = legalName
      }
      if (tradeName) {
        requestBody.tradeName = tradeName
      }
      if (pharmacyName) {
        requestBody.pharmacyName = pharmacyName
      }
      if (address) {
        requestBody.address = address
      }
      if (telephone) {
        requestBody.telephone = telephone
      }
      if (fax) {
        requestBody.fax = fax
      }
      if (shippingAddress) {
        requestBody.shippingAddress = shippingAddress
      }
      if (pharmacyOwnerName) {
        requestBody.pharmacyOwnerName = pharmacyOwnerName
      }
      // if (pharmacyOwnerEmail) {
      //   requestBody.pharmacyOwnerEmail = pharmacyOwnerEmail
      // }
      // if (pharmacyOwnerTelephone) {
      //   requestBody.pharmacyOwnerTelephone = pharmacyOwnerTelephone
      // }
      if (pharmacyOwnerEmail !== undefined) {
        requestBody.pharmacyOwnerEmail = pharmacyOwnerEmail === '' ? null : pharmacyOwnerEmail;
      }
      if (pharmacyOwnerTelephone !== undefined) {
        requestBody.pharmacyOwnerTelephone = pharmacyOwnerTelephone === '' ? null : pharmacyOwnerTelephone;
      }
    }

    if (isAdmin !== undefined) {
      server
        .patch(isAdmin && id ? `/users/${id}` : `/users`, requestBody, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then((response) => {
          console.log('Profile updated successfully:', response.data)
          setSuccess(true)
          setToastStatus({
            toastStatus: 'success',
            msg: response.data.message,
          })
          onGetUserData()
        })
        .catch((error) => {
          console.error('Error updating profile:', error)
          setError(error)
          setToastStatus({
            toastStatus: 'error',
            msg: 'Error updating profile',
          })
        })
    } else {
      console.error('isAdmin is not defined')
    }
  }

  //      2.2.1.8 - handle logout
  const onLogout = () => {
    // localStorage.removeItem('accessToken')
    localStorage.clear()
    setUser(null)
    setAccessToken(null)
    setIsAdmin(null)
  }

  // 2.3 - return needed states, states handlers and functions in you provider
  return (
    <AuthenticationContext.Provider
      value={{
        user,
        error,
        success,
        isAdmin,
        isLoading,
        loading,
        accessToken,
        toastStatus,
        collapsed,
        module,
        setCollapsed,
        setIsAdmin,
        setError,
        setUser,
        setModule,
        setIsLoading,
        setToastStatus,
        onLogin,
        onVerify,
        onChangePassword,
        onChangePasswordProfile,
        onSendForgetPasswordEmail,
        onGetUserData,
        onProfileUpdate,
        onRegister,
        onLogout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
