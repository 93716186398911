import React, { forwardRef, useState } from 'react'
import AddEditAds from './AddEditAds'
import DeleteAds from './DeleteAds'

const ModifyAds = forwardRef((props, ref) => {
  let emptyAds = {
    title: '',
    link: '',
    expiryDate: '',
    message: '',
    startDate: '',
    status: '',
    image: '',
  }

  const [ads, setAds] = useState(emptyAds)
  return (
    <>
      <AddEditAds
        ref={ref}
        getData={props.getData}
        advertisement={ads}
        setAdvertisement={setAds}
      />
      <DeleteAds
        ref={ref}
        getData={props.getData}
        ads={ads}
        setAds={setAds}
        emptyAds={setAds}
      />
    </>
  )
})

export default ModifyAds
