import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const StatusDropdown = ({ onChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

  // Handle status selection and trigger the onChange prop
  const handleStatusSelect = (status) => {
    onChange(status)  // Call the parent function with the selected status
    toggleDropdown()  // Close the dropdown after selection
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      direction='left'
      style={{ height: '40px', width: '100px' }}
    >
      <DropdownToggle
        className='bg-white '
        style={{
          border: '1px solid #CED4DA',
          borderRadius: '4px',
          height: '40px',
          width: '100px',
        }}
      >
        <div className='d-flex justify-content-center gap-2'>
          <span>Filter</span>
          <span className='mdi mdi-filter-outline'></span>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className='border-bottom'
          onClick={() => handleStatusSelect('available')}
        >
          Available
        </DropdownItem>
        <DropdownItem
          className='border-bottom'
          onClick={() => handleStatusSelect('Coming soon')}
        >
          Soon..
        </DropdownItem>
        <DropdownItem
          className='border-bottom'
          onClick={() => handleStatusSelect('pending')}
        >
          Pending
        </DropdownItem>
        <DropdownItem
          className='border-bottom'
          onClick={() => handleStatusSelect('active')}
        >
          Active
        </DropdownItem>
        {/* Clear Filter option */}
        <DropdownItem onClick={() => handleStatusSelect('')}>
          Clear Filter
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusDropdown
