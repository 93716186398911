import React, { useContext } from 'react'
import { AuthenticationContext } from '../Auth/authentication.context'
import { Navigate, Outlet } from 'react-router-dom'

const PharmacyPrivate = () => {
    const { user } = useContext(AuthenticationContext)

    return <>{user?.role === 'pharmacy' ? <Outlet /> : <Navigate to='/' />}</>
}

export default PharmacyPrivate