import React, { useState, useEffect, useRef } from 'react'
import { InputText } from 'primereact/inputtext'
import server from '../../../server'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { ToastPopUp } from '../../shared/utils'

const InviteEditManufacturersUsers = ({
  visible,
  setVisible,
  getData,
  type,
  userData,
}) => {
  const toast = useRef(null)
  const [formData, setFormData] = useState({
    Manufacturers: [],
    addedManufacturer: [],
    email: '',
    fullname: '',
    emailError: '',
    fullnameError: '',
    submitted: false,
    loading: false,
  })

  useEffect(() => {
    getManufacturers()
  }, [])
  useEffect(() => {
    if (type === 'edit') {
      const formattedManufacturers = userData?.manufacturers.map(
        (manufacturer) => ({
          id: manufacturer.id,
          name: manufacturer.name,
        })
      )
      setFormData((prevState) => ({
        ...prevState,
        addedManufacturer: formattedManufacturers,
        email: userData?.email,
        fullname: userData?.displayName,
      }))
    }
  }, [userData])

  const getManufacturers = () => {
    server
      .get(`/manufacturers/?ordable=true`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturers = response.data?.Manufacturers
        // Map through manufacturers to extract only id and name
        const formattedManufacturers = manufacturers.map((manufacturer) => ({
          id: manufacturer.id,
          name: manufacturer.name,
        }))
        setFormData((prevState) => ({
          ...prevState,
          Manufacturers: formattedManufacturers,
        }))
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const handleInputChange = (e, key) => {
    const { value } = e.target
    setFormData((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleAddUser = () => {
    setFormData((prevState) => ({ ...prevState, loading: true }))

    const { email, addedManufacturer, fullname } = formData

    let userData = {
      email,
      manufacturers: addedManufacturer?.map((manufacturer) => manufacturer?.id),
      displayName: fullname,
    }

    server
      .post(`/manufacturers/invite`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        ToastPopUp(toast, 'success', 'User Invited')
        getData()
        hideDialog()
      })
      .catch((e) => {
        ToastPopUp(toast, 'error', 'Error', e.response.data.message)
        setFormData((prevState) => ({ ...prevState, loading: false }))
      })
  }

  const editUser = () => {
    setFormData((prevState) => ({ ...prevState, loading: true }))
    const { addedManufacturer, fullname } = formData
    const newMan = addedManufacturer.filter(
      (manufacturer) =>
        !userData.manufacturers.some((man) => man.id === manufacturer.id)
    )

    const deletedMan = userData.manufacturers.filter(
      (man) =>
        !addedManufacturer.some((manufacturer) => manufacturer.id === man.id)
    )
    let userEditableData = {}

    if (newMan?.length > 0) {
      userEditableData.newMan = newMan.map((manufacturer) => manufacturer.id)
    }
    if (deletedMan?.length > 0) {
      userEditableData.deletedMan = deletedMan.map(
        (manufacturer) => manufacturer.id
      )
    }
    if (fullname !== userData.displayName) {
      userEditableData.displayName = fullname
    }
    server
      .patch(`/users/${userData?.id}`, userEditableData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        ToastPopUp(toast, 'success', 'User Invited')
        getData()
        hideDialog()
      })
      .catch((e) => {
        ToastPopUp(toast, 'error', 'Error', e.response.data.message)
        setFormData((prevState) => ({ ...prevState, loading: false }))
      })
  }

  const hideDialog = () => {
    setVisible(false)
    setFormData({
      ...formData,
      addedManufacturer: null,
      email: '',
      fullname: '',
      submitted: false,
      emailError: '',
      fullnameError: '',
      loading: false,
    })
  }

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleBlur = (key) => {
    const { email, fullname } = formData
    if (key === 'email') {
      if (email.trim() !== '' && !isEmailValid(email)) {
        setFormData((prevState) => ({
          ...prevState,
          emailError: 'Invalid email format',
        }))
      } else if (email.trim() === '') {
        setFormData((prevState) => ({
          ...prevState,
          emailError: 'Email is required',
        }))
      } else {
        setFormData((prevState) => ({ ...prevState, emailError: '' }))
      }
    } else if (key === 'fullname') {
      if (fullname.trim() === '') {
        setFormData((prevState) => ({
          ...prevState,
          fullnameError: 'Fullname is required',
        }))
      } else {
        setFormData((prevState) => ({ ...prevState, fullnameError: '' }))
      }
    }
  }

  const manufactureDialgeFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' outlined onClick={hideDialog} />
      <Button
        label={type === 'add' ? 'Invite User' : 'Save'}
        icon='pi pi-check'
        disabled={
          (type === 'add' &&
            (formData?.addedManufacturer?.length === 0 ||
              !isEmailValid(formData.email) ||
              !formData?.fullname ||
              !!formData?.fullnameError)) ||
          (type !== 'add' &&
            (formData?.addedManufacturer?.length === 0 ||
              !formData?.fullname ||
              !!formData?.fullnameError))
        }
        loading={formData?.loading}
        onClick={type === 'add' ? handleAddUser : editUser}
      />
    </>
  )

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        visible={visible}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Invite Manufacturer User'}
        modal
        className='p-fluid'
        footer={manufactureDialgeFooter}
        onHide={() => hideDialog()}
      >
        <div className='my-1'>
          <div className='w-full my-3'>
            <label htmlFor='manufacturersNames' className='font-bold d-block'>
              Manufacturer Name
            </label>

            <MultiSelect
              value={formData?.addedManufacturer}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  addedManufacturer: e.value,
                }))
              }
              options={formData?.Manufacturers}
              optionLabel='name'
              filter
              placeholder='Select Manufacturer'
              maxSelectedLabels={3}
              style={{ width: '100%' }}
            />

            {formData.submitted &&
              !formData.addedManufacturer?.manufacturer && (
                <small className='p-error'>
                  Manufacturer name is required.
                </small>
              )}
          </div>
          <div className='my-3'>
            <label htmlFor='fullname' className='font-bold d-block'>
              Fullname
            </label>
            <InputText
              id='fullname'
              type='text'
              value={formData.fullname}
              onChange={(e) => handleInputChange(e, 'fullname')}
              onBlur={() => handleBlur('fullname')}
              placeholder={'Enter Fullname'}
              style={{ width: '100%' }}
            />
            {formData.submitted && !formData.fullname && (
              <small className='p-error'>Fullname is required.</small>
            )}
            {formData.fullnameError && (
              <small className='p-error'>{formData.fullnameError}</small>
            )}
          </div>
          <div className='my-3'>
            <label htmlFor='email' className='font-bold d-block'>
              Email
            </label>
            <InputText
              id='email'
              type='email'
              value={formData.email}
              onChange={(e) => handleInputChange(e, 'email')}
              onBlur={() => handleBlur('email')}
              placeholder={'Enter Email Address'}
              style={{ width: '100%' }}
              disabled={type === 'add' ? false : true}
            />
            {formData.submitted && !formData.email && (
              <small className='p-error'>Email is required.</small>
            )}
            {formData.emailError && (
              <small className='p-error'>{formData.emailError}</small>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default InviteEditManufacturersUsers
