import React, { forwardRef, useState } from 'react'
import AddEditManufacturer from './AddEditManufacturers'
import DeleteManufacture from './DeleteManufacturers'


const ModifyManufacture = forwardRef((props, ref) => {
  let emptyManufacturer = {
    id: null,
    name: '',
    gpIsOrdable: true,
    email: '',
    leadTime: null,
    cutOffTime: '',
  }

 
  const [manufacturer, setManufacturer] = useState(emptyManufacturer)
  return (
    <>
      <AddEditManufacturer
        ref={ref}
        getData={props.getData}
        manufacturers={manufacturer}
        setManufacturers={setManufacturer}
        emptyManufacturer={emptyManufacturer}
      />
      <DeleteManufacture
        ref={ref}
        getData={props.getData}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        emptyManufacturer={emptyManufacturer}
      />
    </>
  )
})

export default ModifyManufacture
