import React, { useContext, useState, useEffect } from 'react'
import SidebarElement from './SidebarElement'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { AuthenticationContext } from '../../../Auth/authentication.context'

const PharmacySidebarContent = () => {
  const navigate = useNavigate()

  const [nodes] = useState([
    {
      key: '1',
      label: 'Dashboard',
      link: '/',
      icon: 'mdi mdi-home',
    },
    {
      key: '2',
      label: 'Profile',
      link: '/pharmacy/profile',
      icon: 'mdi mdi-account',
    },
    {
      key: '3',
      label: 'Orders',
      link: '/pharmacy/orders',
      icon: 'mdi mdi-file-document-multiple',
    },
    {
      key: '4',
      label: 'Manufacturers',
      link: '/pharmacy/manufacturers',
      icon: 'mdi mdi-factory',
    },
    {
      key: '5',
      label: 'Logout',
      link: 'logout',
      icon: 'mdi mdi-logout',
    },
    // {
    //   key: '6',
    //   label: 'Manufacturer orders',
    //   link: '/pharmacy/manufacturerOrders',
    //   icon: 'mdi mdi-logout',
    // },
  ])

  const { collapsed, module } = useContext(AuthenticationContext)
  const [userModule, setUSerModule] = useState('')

  useEffect(() => {
    if (module) {
      setUSerModule(module)
    }
  }, [module])
  return (
    <>
      <li>
        {collapsed ? (
          userModule === 'Origins Direct' && (
            <a href='/pharmacy/add-orders' className='has-arrow'>
              <i className='mdi mdi-pen'></i>
              <span className=''>New Order</span>
            </a>
          )
        ) : (
          <div className='d-flex justify-content-center w-100 p-2'>
            {userModule === 'Origins Direct' && (
              <Button
                type='button'
                label='Create New Order'
                onClick={() => navigate('/pharmacy/add-orders')}
              />
            )}
          </div>
        )}
      </li>
      {nodes.map((menuItem) => (
        <SidebarElement
          key={menuItem.key}
          name={menuItem.label}
          link={menuItem.link}
          iconClass={menuItem.icon}
          notifications={menuItem.notifications}
          children={menuItem.children}
        />
      ))}
    </>
  )
}

export default PharmacySidebarContent
